@import '../../resources/media-queries.scss';

.menu{
    display: none;
    @include desktop{
        position: relative;
        min-height: 100vh;
        display: block;
        background: #fff;
        width:17%;
        box-shadow: 2px 5px 10px 0px rgba(0,0,0,0.15);
        &__down{
            width:12px;
            height:12px;
            position: relative;
            right:20px;
            top:25px;
            
        }
        &__item-with-submenu{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;
        }
        &__logo{
            width:165px;
            height:65px;
            display: flex;
            margin:20px auto;
            object-fit: contain;
        }
        &__gradient{
            position: absolute;
            top:0;
            height:155px;
            object-fit: contain;
            width:100%;
        }
        &__title{
            color: #fff;
            padding:40px 0 0 40px;
            font-size: 24px;
        }
        &__item-box{
            display: flex;
            flex-direction: row;
            cursor: pointer;
            height:60px;
        }
        &__lower-container{
            margin-top: 70px;
        }
        &__upgrade-box{
            background-image: url('/assets/couple-gradient.png');
            border-top-left-radius: 32px;
            border-top-right-radius: 48px;
            padding:40px 20px; 
        }
        &__couple-img{
            width:172px;
            height:225px;
            margin-top: 30px;
        }
        &__upgrade-text{
            font-family: Roboto;
            font-size: 12px;
            font-weight: 600;
            padding:10px;
  
        }
        &__upgrade-link{
            display: flex;
            flex-direction: row;
            cursor: pointer;
            color:initial;
            text-decoration: none;
            img{
                width:24px;
                height:24px;
                position:relative;
                top:7px;
                right:10px;
            }
            h4{
                color: #e66300;
            }
        }

        &__img{
            width:26px;
            height:26px;
            position: relative;
            top:20px;
        }
        &__list{
            display: flex;
            flex-direction: column;
            margin:60px auto 40px;
            list-style-type: none;
        }
        &__item-name{
            font-size: 14px;
            line-height: 17px;
            font-family: 'Roboto', sans-serif!important;
            color: #000;
            margin-bottom: 20px!important;
            font-weight:500;
            padding:25px 0 10px 8px;
        }
        &__orange{
            font-size: 14px;
            line-height: 17px;
            font-family: 'Roboto', sans-serif!important;
            color: #e66300;
            margin-bottom: 20px!important;
            font-weight:500;
            padding:25px 0 10px 8px;
        }
        &__item-name:hover{
            border-bottom: 1px solid #fff;
        }
        &__account-box{
            display: flex;
            flex-direction: row;
            margin:100% 0 0 40px;
            cursor: pointer;
        }
        &__account-img{
            width:20px;
            height:20px;
            position: relative;
            top:20px;
        }
        &__submenu{
            margin-left: 20px;
            img{
                width:16px;
                height:16px;
                margin-top: 5px;
            }
        }
    }
}