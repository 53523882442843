@import '../../../resources/media-queries.scss';

.events{
    &__events-container{
        margin:20px;
    }
    &__event{
        border-radius: 8px;
        border: solid 1px #e66300;
        padding:20px;
        position: relative;
        z-index: 10;
    }
    &__head{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__event-name{
        border-radius: 21px;
        border: solid 1px #e66300;
        font-size: 12px;
        padding:10px 15px;
    }
    &__event-type{
        font-size: 12px;
        font-weight: 600;
        position: relative;
        top:10px;
        color: #000;
    }
    &__info-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:100%;
        margin-top: 20px;
    }
    &__left{
        font-size: 12px;
        font-weight: 500;
        padding:10px 0;
    }
    &__right{
        font-size: 12px;
        font-weight: 500;
        padding:10px 0;
    }
    &__section{
        width:48%;
    }
    &__edit{
        cursor: pointer;
        color: #e66300;
    }
    &__slug{
        color: #006aff;
    }
    &__title{
        display: none;
    }
    .orange{
        cursor: pointer;
        color: #e66300;
    }
    @include desktop{
        padding:0!important;
        &__events-container{
            display: none;
        }
        &__title{
            display: block;
            font-family: Roboto;
            font-size: 18px;
            padding:40px 20px;
        }
        &__event-name{
            padding:3px 0;
        }
    }
}