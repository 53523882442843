@import '../../resources/media-queries.scss';

.admin-header{
    &__container{
        position: relative;
        padding:40px;
    }
    &__title{
        font-family: Roboto!important;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        text-align: center;
    }
    &__gradient{
        position: absolute;
        top:0;
        width:98%;
        z-index: 1;
        object-fit: cover;
        height:240px;
    }
    @include desktop{
        display: none;
    }
}