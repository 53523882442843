@import '../../resources/media-queries.scss';

.civil{
    &__container{
  
    }
    &__title{
        font-size: 24px;
        padding:20px 20px 15px;
    }
    &__introduction{
        font-size: 16px;
        font-weight: 500;
        padding:10px;
        text-indent: 10px;

    }
    &__step{
        display: flex;
        flex-direction: row;
        margin:15px 0;
    }
    &__number{
        background-image: linear-gradient(to bottom, #f7ebd7, rgba(255, 244, 229, 0));
        height:64px;
        object-fit: cover;
        width:64px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        border-radius:50%;
        h3{
            font-size: 38px;
        }
    }
    &__info{
        width:80%;
    }
    &__step-title{
        padding:0 0 10px 25px;
    }
    &__time-box{
        display: flex;
        flex-direction: row;
        img{
            width:20px;
            height:20px;
            margin-right: 10px;
            margin-top: 3px;
        }
        h4{
            font-size: 14px;
        }
    }
    &__info-text{
        font-size: 14px;
        padding:10px 0 10px 30px;
    }
    &__list-title{
        padding:20px 0 0 30px;
    }
    &__list-box{
        display: flex;
        flex-direction: row;
        img{
            width: 12px;
            height:12px;
            object-fit: contain;
            position: relative;
            right:5px;
            top:2px;
        }
    }
    &__list{
        list-style-type:none;
        padding-left: 0;
        li{
        
            font-size: 14px;
            padding-bottom: 10px;
            font-weight: bold;
        }
    }
    &__wish{
        text-align: center;
        padding-bottom: 20px;
    }
    &__step-1{
        padding:20px;
   
    }
    &__step-2{
        background-image:url("/assets/blog-2.png");
        background-repeat: no-repeat;
        padding:20px;
    }
    @include tablet{

    }
    @include desktop{
        &__whole-wrapper{
         
        }
        &__img-box{
            background-image: linear-gradient(to left, #f7ebd7, rgba(255, 244, 229, 0));
            min-height: 200vh;
            width:25%;
            position: absolute;
            right:0;
            top:0;
            img{
                margin-top: 300px;
            }
        }
        &__container{
            width:80%;
            padding-left: 20px;
        }
        &__title{
            padding:40px 0 30px 10%;
        }
        &__introduction{
            padding-left: 10%;
            width:40%;
        }
        &__step{
            padding-left: 10%;
            object-fit: cover;
        }
        &__step-1, &__step-2{
            background-image: none;
        }
        &__step-1{
            width:60%;
        }
        &__step-2{
            padding-left: 15%;
            width:60%;
        }
        &__step-3{
            padding-left: 20%;
            width:40%;
        }
        &__step-3{
            padding-left: 20%;
            width:40%;
        }
        &__step-4{
            padding-left: 30%;
            width:40%;
        }

        &__wish{
            font-size: 22px;
        }
        &__number{
            width:82px;
            height:82px;
            
        }
    }
}