@import '../../resources/media-queries.scss';

.providers{
    &__title{
        text-align: center;
        padding-top: 30px;
    }
    &__top{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin:30px auto;
    }
    &__categories-slider{
        width:95%;
    }
    &__line{
        height:2px;
        background-color: #e66300;
        width:18px;
        margin:10px auto;
    }
    &__partners-icon{
        width:24px;
        height:24px;
        border: 1px solid #e66300;
        border-radius: 50%;
        margin-right: 6px;
        position: relative;
        bottom:25px;

    }
    &__mobile-provider{
        margin-bottom: 50px;
    }
    &__top-provider{
        position: relative;
    }
    &__shadow{
        position: absolute;
        top:50px;
        width:100%;
        left:0;
        height:100%;
        object-fit: cover;
    }
    &__category-wrapper{
        display: flex!important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{
            width:80px;
            height:60px;
        }
    }
    &__provider-name{
        padding-top: 15px;
        position: relative;
        z-index: 10;
    }
    &__details{
        display: flex;
        flex-direction: row;
        margin:15px 0;
        justify-content: space-between;
        margin-right: 20px;
        h5{
            text-align: right;
        }

    }
    &__name-info{
        padding-right: 10px;
        text-align: left;
    }
    &__specific-detail{
        text-align:right;
    }
    &__blue{
        color: #007ab4;
    }
    &__container{
        margin:30px 20px;
    }
    &__main-img{
        width:100%;
        height:250px;
        object-fit: cover;
        border-radius: 5px;
    }
    &__category-selected{
        border: solid 1px #e66300;
    }
    &__full{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.68) 64%, #fff);
        margin-bottom: 30px;
    }
    &__no-providers{

    }
    &__contact-us{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 12px;
        width:90%;
        box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.2);
        background-color: #fff;
        z-index: 49;
        position: fixed;
        bottom:0;
        padding: 10px 20px;
        img{
            width:36px;
            height:30px;
        }
        h5{
            font-size: 12px;
            text-align: center;

        }
        button{
            background-color: #fff;
            border:none;
            width:200px;
            height:40px;
            border-radius: 12px;
        box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.2);
        font-size: 12px;
        font-weight: bold;
        }
    }
    @include tablet{
    &__wrapper{
        padding:40px;
    }
    &__more-info{
        width:50%;
        margin:20px auto;
    }
    &__provider-name{
        text-align: center;
    }
    &__main-img{
        height:350px;
    }
    &__contact-us{
        width:90%;
        padding:20px 40px;
        h5{
            width:50%;
        }
    }

    }
    @include desktop{
        &__contact-us{
            z-index:56;
            width:30%;
            right:10%;
            bottom:40px;
            padding:40px;
            h5{
                padding-top: 10px;
                width:45%;
            }
            button{
                width:150px;
            }
            img{
                width:46px;
                height:42px;
            }
        }
        &__details{
            margin:20px 0;
            margin-right: 20px;
            font-size: 16px;
            width:100%;
        }
        &__main-img{
           width:300px;

        }
        &__top{
            width:100%;
        }
         &__shadow{
        top:90px;
    }
        &__partners-icon{
            top:0;
        }
        &__menu-wrapper{
            width:15%;
        }
        &__provider-name{
            bottom:45px;
            left:10px;
            font-size: 18px;
        }
        &__container-more-info{
            width:50%;
        }
        &__more-info{
            margin-left: 100px;
            width:100%;

        }
        &__container{
            padding:20px 5%;
        }
        &__menu-wrapper{
            margin-right: 40px;
            border-right: solid 2px rgba(0, 0, 0, 0.1);
            padding-right: 20px;
        }
        &__category{
            display: flex;
            flex-direction: row;
            min-width: 200px;
            margin:20px 0;
            border-radius: 8px;
            cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
            img{
                width:80px;
                height:60px;
                margin-right: 10px;
            }
            h5{
                font-size: 14px;
                position: relative;
                top:18px;
            }
        }

        &__container{
            display: flex;
            flex-direction: row;
        }
        &__description{
            max-width: 80%;
            display: flex;

        }

    }
}
