@import '../../resources/media-queries.scss';

.options{
    &__title{
        padding-bottom: 10px;
        padding-top: 20px;
    }
    &__box{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        button{
            height:58px;
            width:70%;
            margin:15px auto;
            

        }
        
    }
    &__writing{
        font-weight: light;
        font-size: 14px;
       
    }
}