@import '../../resources/media-queries.scss';


    .mobile-menu{
        &__icon{
            width: 28px;
            position: absolute;
            top: 42px;
            right: 22px;
            cursor: pointer;
        }
        &__close-icon {
            display: none;
            position: absolute;
            width: 22px;
            right: 6%;
            top: 26px;
            z-index: 20;

            &.active {
              display: block;
            }
          }
        &__links {
            list-style: none;
            width: 90%;
            float: right;
            margin-right: 0;
            z-index: 20;
            clear: both;
            overflow: hidden;
            top: 0;
            right: 0;
            height: 100%;
            margin-top: 0;
            padding-top: 80px;
            background-color: #fff;
            border-right: solid 1px lightgrey;
            position: fixed;
            transform: translateX(100%);
            transition: transform .3s;
            overflow-y: auto;
         


            &.active {
              transform: translateX(0);
            }
        }
        &__list-item{
            font-size: 18px;
            padding:10px 0 20px 20px;
            
        }
        &__link--menu{
            font-size: 22px;
            font-weight: bolder;
            margin: 10px 0 20px;
            color: #ffff;
            padding-left: 8px;
        }
        &__link{
            text-decoration: none;
            color:initial;
            color: #ffff;
        }
        &__title{
            padding:0 0 20px 18px;
        }
        @include tablet{
            &__icon{
                right:42px;
            }
        }
        @include desktop{
            display: none;
            &__icon{
                display: none;
            }
        }
    }
