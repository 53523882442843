@import '../../../resources/media-queries.scss';

.guests{

    &__wrapper{

        width:350%;
    }
    &__non-guests-wrapper{
        width:130%!important;
        margin-top: 20px!important;
    }
    &__invitation-wrapper{
        width:250%;
    }
    &__full-wrapper{
        justify-content: wrap;
        overflow-x: scroll;
        position: relative;
        top:20px;
    }
    &__page-content{
        padding:20px;
    }
    &__table-title{
        padding-bottom: 20px;
    }
    &__heading{
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #48525D;
    }
    &__row{
        display: flex;
        flex-direction: row;
        padding-bottom: 5px;

        margin:10px 0;
        padding-bottom: 5px;
        border-bottom:1px solid #D1E9F7;
    }

    &__head-title{
        width:400px;
        padding-right: 10px;
    }

    &__cell{
        width:400px;
        word-break: break-word;
        padding-right: 10px;

        img{
            width:18px;
            height:18px;
            cursor: pointer;
            margin-right: 20px;
        }
        h4{
            color: #48525D;
            font-size: 12px;
            line-height: 14px;
        }
    }
    &__add{
        bottom:70px;
    }
    &__number h4{
        background-color: #f5fbe9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width:30px;
        height:30px;
        color:#90c923!important;
        position: relative;
        bottom:5px;

    }
    &__form{
        display: flex;
        flex-direction: column;


        button{
            background: #1992D4;
            border-radius: 4px;
            border: none;
            height: 40px;
            width: 126px;
            color: #fff;
            font-size: 12px;
            position: relative;
            margin:10px 0 0;
        }
    }
    &__input{
        margin: 10px 0;
        background: #FFFFFF;
        border: 1px solid #A3D3EE;
        border-radius: 4px;
        height: 40px;
        width: 100%;
        font-size: 14px;
    }
    &__input:focus{
        outline:none;
    }
    .pagination {
        list-style-type: none;
        display: flex;

        align-items: center;
        margin: 10px 0;;
        padding-bottom: 20px;

        li {
          cursor: pointer;
          color: #e66300;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          border:none;
          padding: 5px 0;
          margin: 0 5px;
          font-weight: bolder;

          a {
            padding: 5px 10px;
            color:#e66300;
          }

          a:focus {
            outline: none;
          }

          &:hover {
            background-color: #e6e6e6;
            color: #e66300;

            a {
              color:#e66300;;
            }
          }
        }

        .active {
          border:1px solid #e66300;;
          color: #e66300;;

          a {
            color: #e66300;
          }
        }

        .previous, .next {
          border: 1px solid grey;

        }
      }
      &__top{
          display: flex;
          flex-direction: column;
          width:100%;
          margin-top: 20px;

      }
      &__checkbox-container{
          position: relative;
          left:20px;
          top:5px;
          width:20%;
      }
      &__add-part{

      }
      &__search-part{
       margin-top: 20px;
          img{
              width:20px;
              height:20px;
              position: relative;
              left:65%;
              bottom:40px;
          }
          input{
              width:70%;
          }
      }
      &__title{
          padding:20px 0;
      }
    @include tablet{
        &__wrapper{
            width:100%;
        }
    }
    @include desktop{
        display: flex;
        flex-direction: row;
        &__top-search{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            select{
                width:20%;
            }
        }
        &__top{

            flex-direction: row;
            justify-content: space-between;
        }
        &__checkbox-container{
            position: relative;
            left:20px;
            top:5px;
            width:25%;
        }
        &__form{
            flex-direction: row;
            button{
                right:20px;

            }
        }
        &__add-part{
            width:60%;
          }
          &__search-part{
            width:40%;
            position: relative;
            margin-top: 0;
            left:12%;;

            img{
                width:20px;
                height:20px;
                position: absolute;
                top:50px;

            }
            input{

            }
        }
        &__title{
            padding-bottom: 20px;
        }
        &__page-content{
            width:70%;
            padding:80px 0 0 60px;
        }
        &__wrapper{
            width:100%;
        }
        &__full-wrapper{

            overflow-x: hidden;
        }
        &__row{
            padding-bottom: 10px;
            margin:20px 0;
        }
        &__heading{
            margin-bottom: 30px;
            font-size: 16px;
        }
        &__table-title{
            text-align: center;
            padding-bottom: 40px;
            font-size: 24px;
        }
        &__cell h4{
            font-size: 14px;
        }
        &__cell{

        }
        .pagination{
            justify-content: center;
        }
    }
}
