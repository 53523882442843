@import './media-queries.scss';
.standard{
    h1, h2, h3{
        font-family: 'Playfair Display', serif;
    }
    h4, h5, p, li{
        font-family: 'Montserrat', sans-serif;
    }
    h1, h2, h3, h4, h5, p, li{
        margin:0;
    }
    button{
        cursor: pointer;
    }
    &__link{
        text-decoration: none;
        color:initial;
        cursor: pointer;
    }

    &__link--profile{
        color: #e66300;
        cursor: pointer;
    }
    &__checked{
      width:20px;
      cursor:pointer;
    }
    &__done-row{
      border-color: #70db70!important;
    }
    .incomplete-mobile{
        width:100%;
        object-fit: contain;
    }
    &__only-desktop{
        display: none;
    }
    &__categories{
      margin-top: 30px;
      h4{
        padding-bottom: 5px;
        font-size: 14px;
      }
    }
    button:focus{
        outline:none;
    }
    .pagination {
        list-style-type: none;
        display: flex;
        align-items: center;
        margin: 10px auto;
        padding-bottom: 20px;
        justify-content: center;

        li {
          cursor: pointer;
          color: #1992D4;
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          border:none;
          padding: 5px 0;
          margin: 0 5px;
          font-weight: bolder;

          a {
            padding: 5px 10px;
            color:#1992D4;
          }

          a:focus {
            outline: none;
          }

          &:hover {
            background-color: #ffffff;
            color: #1992D4;

            a {
              color:#1992D4;;
            }
          }
        }

        .active {
          border:1px solid #1992D4;;
          color: #1992D4;;

          a {
            color: #1992D4;
          }
        }

        .previous, .next {
          border: 1px solid rgb(251, 250, 250);

        }
      }
    &__footer{
        background: #1992D4;
        width:100%;
        padding:20px 0;

    }
    &__notification-icon{
      width:20px;
    }
    &__checked-icon{
      width:20px;
      height:auto;
      object-fit: contain;
    }
    &__checked{
      display: flex;
      img{
        width:20px;
        height:auto;
        object-fit: contain;
      }
    }
    &__unread{
      background-color: #fff1e6;
    }
    &__qr{
      border-radius: 12px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      border:1px solid #e66300;
      color:black;
      height: 48px;
      border: none;
      width: 150px;
      font-weight: bold;
      position: relative;
      top:5px;
    }
    &__qr-box{
      margin:20px auto;
      display: flex;
      justify-content: center;
    }
    &__menu-item{
        color:#fff;
        font-size: 16px;
        padding:5px 0;
        text-align: center;
    }
    &__footer-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:30px 0;
        padding:0 20px;
        h5{
            font-size: 10px;
            color:#fff;
        }
    }
    &__disclaimer{
        padding:20px;
        text-align: center;
        h5{
            color:#fff;
            font-size: 14px;
        }
        a{
            color:#fff;
        }
    }

    &__loading-gif {
      width: 150px;
      height: 75px;
      border-radius: 20px;

      top: 10%;
    }

    &__no-interaction {
      pointer-events: none;
    }

    @include tablet{
        &__footer-box{
           padding:0 25%;
        }
    }
    @include desktop{
      &__no-items{
        padding:40px 0 40px 20px;
      }
        &__only-mobile{
            display: none;
        }
        &__only-desktop{
            display: block;
        }
        &__menu-item{
            padding:10px 0;
        }
        &__footer-box{
            padding:0 10%;
            h5{
                font-size: 12px;
            }
         }
    }
}

body {
  overflow: auto;
}

._loading_overlay_wrapper--active {
  height: 100vh;
  overflow: hidden;
}

._loading_overlay_overlay {
  background: rgba(0,0,0,0.9)!important;
}
