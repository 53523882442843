@import '../../../resources/media-queries.scss';

.config{
    padding:0;
    &__avertisment{
        position: relative;
        color:red;
        font-weight: bold;
        font-size: 12px;

    }
    &__top{
        display: flex;
        flex-direction: row;
        margin:30px 0 0;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        padding-bottom: 15px;
    }
    &__stars{
        border:1px solid #e66300;
        border-radius: 50%;
        width:30px;
        height:30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        img{
            width:18px;
            height:18px;
        }
    }
    &__top-text{
        h5{
            font-weight: 500;
            font-size: 12px;
            padding:2px 0;
        }
    }
    &__section{
        margin:20px 40px;
        padding-bottom: 30px;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
    &__edit-capacity{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        justify-content: space-between;
    }
    &__capacity-box{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: solid 1px #e66300;
        height:62px;
        width:80%;
        margin-bottom: 10px;
    }
    &__capacity-name{
        font-size: 14px;
        font-weight: 500;
        padding-right: 10px;
    }
    &__capacity-info{
        font-size: 14px;
    }
    &__guests{
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__guest{
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding:10px;
        h3{
            font-size: 12px;
        }
    }
    @include desktop{
        &__edit-capacity{
            flex-direction: row;
        }
        &__capacity-box{
            width:20%;
            min-width: 200px;
            padding:0 5px;
        }
    }
}