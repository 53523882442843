@import '../../resources/media-queries.scss';


    .hamburger{
        &__icon{
            width: 28px;
            position: absolute;
            top: 42px;
            left: 22px;
            cursor: pointer;
            z-index: 10;
        }
        &__logo{
            width:160px;
            height:58px;
            display: flex;
            justify-content: center;
            margin:0 auto 20px;
        }
        &__close-icon {
            display: none;
            position: absolute;
            width: 22px;
            right: 15%;
            top: 26px;
            z-index: 101;

            &.active {
              display: block;
            }
          }
        &__links {
            list-style: none;
            width: 77%;
            float: left;
            margin-left: 0;
            z-index: 20;
            clear: both;
            overflow: hidden;
            top: 0;
            left: 0;
            height: 100%;
            margin-top: 0;
            padding-top: 80px;
            background-color: #fff;
            border-right: solid 1px lightgrey;
            position: fixed;
            transform: translateX(-100%);
            z-index: 100;
            transition: transform .3s;
            overflow-y: auto;


            &.active {
              transform: translateX(0);
              box-shadow: 50px 7px 12px 32px rgba(0,0,0,0.43);
            }
        }
        &__list-item{
            font-size: 18px;
            padding:10px 0 20px 20px;

        }

        &__link{
            text-decoration: none;
            color:initial;
            color: #ffff;
        }
        &__title{
            padding:0 0 20px 18px;
        }
        &__item-box{
            display: flex;
            flex-direction: row;
            height:60px;
            margin-left: 10%;
        }
        &__item-name{
            font-size: 14px;
            line-height: 17px;
            font-family: 'Roboto', sans-serif!important;
            color: #000;
            margin-bottom: 20px!important;
            font-weight:500;
            padding:25px 0 10px 8px;
        }
        &__menu-icon{
            width:26px;
            height:26px;
            position: relative;
            top:20px;
        }
        &__logout{
            margin-top: 60px;
        }
        @include tablet{
            &__icon{
                right:42px;
            }
        }
        @include desktop{
            &__icon{
                display: none;
            }
        }
    }
