@import '../../resources/media-queries.scss';

.blog{
    &__main-img{
        width:100%;
        z-index: 1;
        height:350px;

    }
    &__image-div{
        position: relative;
    }

    &__read-link {
      text-decoration: none;
    }

    &__image-shadow{
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: inset -6px -33px 35px 9px rgba(255,255,255,0.98);
        box-shadow: inset -6px -33px 35px 9px rgba(255,255,255,0.98);
        z-index: 54;
        top: 0;
        left: 0;
    }
    &__article-image-shadow{
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: inset -27px -33px 35px 14px rgba(255,255,255,0.74);
        box-shadow: inset -27px -33px 35px 14px rgba(255,255,255,0.74);
        z-index: 54;
        top: 0;
        left: 0;
    }
    &__article-time{
        text-align: center;
        font-size: 10px;
        padding-top: 3px;
    }
    &__featured-section-info{
        padding:20px;
    }
    &__time-box{
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        justify-content: center;
        h4{
            font-size: 12px;
            padding-top: 1px;
        }
    }
    &__circle{
        width:10px;
        height:10px;
        border-radius: 50%;
        background-color: #e66300;
        margin:5px;
    }
    &__featured-section-title{
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding:10px 0 20px;
    }
    &__featured-section-details{
        text-align: center;
        font-family: 'Didact Gothic', sans-serif!important;
    }
    &__read-featured{
        background-color: #e66300;
        border:none;
        width:178px;
        height:65px;
        color:#fff;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:15px auto;
    }
    &__article-wrapper{
        padding:20px 0 20px 20px;
        position: relative;
        background-image: url('/assets/blog-1.png');
        object-fit: cover;
        background-repeat: no-repeat;
    }
    &__subtitle{
        font-size: 20px;
        padding:10px 0;
    }
    &__line{
        background-color: #e66300;
        height:2px;
        width:25px;
        margin-bottom: 20px;
    }
    &__article-img{
        width:150px;
        height:150px;
    }
    &__article-title{
        font-size: 12px;
        text-align: center;
    }
    &__news-wrapper{
        width:150px;
        height:150px;
    }
    &__blog-slider{
        width:100%;
        overflow: hidden;
        .slick-initialized .slick-slide {
            width:160px!important;
            margin-right: 10px!important;
         }
         .slick-list{
            overflow: visible;
        }
    }
    &__categories{
        padding:20px 0 20px 20px;
    }
    &__category-wrapper{
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        margin:10px;
        background-color: #fff;
        padding:10px;
        display: flex!important;
        justify-content: center;
        align-items: center;
    }
    &__categories-slider{
        width:100%;
        overflow: hidden;
        height:200px;
        .slick-initialized .slick-slide {
            width:120px!important;
            margin:5px 20px!important;
         }
         .slick-list{
            overflow: visible;
        }
    }
    &__all-articles{
        padding:20px;
        background-image: url('/assets/blog-2.png');
        background-repeat: no-repeat;
    }
    &__article-container{
        width:100%;
    }
    &__article-whole{
        display: flex;
        flex-direction: row;
        margin:40px 0;
    }
    &__article-only-info{
        width:63%;
    }
    &__article-img{
        margin-right: 10px;
        width: 150px;
        height:160px;
        object-fit: cover;
    }
    &__article-description{
        overflow: hidden;
        position: relative;
        font-family: DidactGothic!important;
        line-height: 1.2em;
        max-height: 6em;

        margin-right: -1em;
        padding-right: 1em;
    }
    &__article-description:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;

      }
      &__article-description:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: white;
      }
      &__article-read-box{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
          h4{
            color:#e66300;
            font-size: 14px;
          }
          img{
            width:14px;
            height:12px;
            position: relative;
            top:3px;
            left:5px;
          }
      }
      &__article-time-box{
          justify-content: flex-start;
      }
      &__wrapper-article-title{
        font-size: 14px;
        padding:10px 0;
      }
    &__newsletter{
        border:1px solid #e66300;
        margin:20px;
        padding:40px;
    }
    &__newsletter-title{
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
    }
    &__newsletter-subtitle{
        text-align: center;
        font-size: 14px;
    }
    &__form{
        margin:20px 0 0;
        display: flex;
        flex-direction: column;
    }
    &__field{
        border: solid 1px #c6c6c6;
        background-color: #fff;
        height:42px;
        margin-bottom: 10px;
        text-indent: 5px;
    }
    &__field:focus{
        outline:none;
    }
    &__decoration{
        width:130px;
        height:300px;
        position: absolute;
        right:0;
        top:-160px;
    }
    &__slider-shadow{
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: inset -6px -33px 35px 9px rgba(255,255,255,0.98);
        box-shadow: inset -6px -33px 35px 9px rgba(255,255,255,0.98);
        z-index: 1;
        top: 0;
        left: 0;
    }
    &__slider-info{
        position: relative;
        bottom:20px;
        background:linear-gradient(to bottom, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.68) 64%, #fff);
        z-index: 4;
    }

    @include tablet{
        &__featured-section-info{
            padding:40px;
        }
        &__article-wrapper{
            padding-left: 40px;
        }
        &__category-wrapper, &__all-articles{
            padding-left: 40px;
        }
        &__newsletter{
            margin:40px;
        }
    }
    @include desktop{
        &__top{
            display: flex;
            position: relative;
            flex-direction: row;
        }

        &__featured-img{
            height:675px;
            margin-top: -200px;
            width:100vw;
            z-index: 1;
            object-fit: cover;
        }
        &__image-shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.86) 60%, #fff 81%, #fff 81%);
          z-index: 2;
          top: -200px;
          right: 0;
          height: 675px;
          box-shadow: none;
        }
        &__featured-section-info{
            width:40%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-left: 50%;
            margin-right: 10%;
            padding: 40px 0;
            z-index: 3;
        }
        &__featured-section-title{
            text-align: left;
            font-size: 26px;
        }
        &__featured-section-details{
            text-align: left;
            width:70%;
            font-size: 18px;
        }
        &__read-featured{
            margin:40px 0;
        }
        &__time-box{
            justify-content: flex-start;
        }
        &__second-section{
            position: relative;
            top:70px;
        }

        &__article-wrapper{
            padding:40px 10%;
        }
        &__subtitle{
            font-size: 36px;
        }

        &__article-img{
            width:350px;
            height:276px;
        }
        &__articles-desktop{
            display: flex;
            flex-direction: row;
            width:75%;
            justify-content: space-between;
        }
        &__slider-info{
            padding:0 20px;
        }
        &__time-box{

        }
        &__article-title{
            text-align: left;
            font-size: 16px;
            width:90%;
        }
        &__bottom-part{
            display: flex;
            flex-direction: row;
            padding:40px 10%;
            justify-content: space-between;
        }
        &__all-articles{
            width:60%;
            background-image: none;

        }
        &__bottom-right{
            width:40%;
            background-image: url('/assets/blog-2.png');
            background-repeat: no-repeat;
            object-fit: cover;
        }
        &__article-description{
            width:70%;
        }
        &__article-only-info{
            margin:40px 0 0 20px;
        }
        &__categories-slider{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width:80%;
        }
        &__category-wrapper{
            height:30px;
            margin:0 5px;
        }
        &__article-read-box{
            display: none;
        }
        &__read-more{
            background-color: #e66300;
            border:none;
            width:138px;
            height:55px;
            color:#fff;
            margin-top: 20px;
        }
        &__form{
            justify-content: center;
            margin:40px auto 0;
            align-items: center;
        }
        &__newsletter-subtitle{
            width:50%;
            margin:10px auto 0!important;
        }
        &__field{
            width:70%;
            margin-bottom: 20px;
        }



    }
}
