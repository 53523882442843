@import '../../resources/media-queries.scss';

.add-modal{
    border-radius: 32px;
    &__textarea{
      height:auto!important;
    }
    &__title{
        font-size: 18px;
        font-family: Roboto;
        color: #2c2c2c;
        padding-bottom: 20px;
    }
    &__field{
        border-radius: 8px;
        border: solid 1px rgba(230, 99, 0, 0.4);
        background-color: #fff;
        height:48px;
        text-indent: 10px;
        width:100%;
    }
    &__description-field{
      height:65px;
    }
    &__field:focus{
        outline: none;
    }
    .story-attachement{
      height:70px;
      button{
        position: relative;

      }
  }
  .website-form-attachement{
    height:85px;
  }
    &__label{
        font-family: Roboto;
  font-size: 12px;
  padding:20px 0 10px;
    }
    &__buttons{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-end;
        margin-top: 20px;
    }
    &__button{
        margin-left: 20px;
        height:48px;
        width:155px;
        border-radius: 12px;
        border:none;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        font-size: 14px;
        font-weight: bold;
        font-family: Roboto;
    }
    &__submit{
        background-color: #e66300;
        color:#fff;
    }
    &__cancel{
        background-color: #fff;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        margin-top: 15px;
        margin-left: 30px;
        height: 24px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }

      input:checked + .slider {
        background-color: #e66300;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #e66300;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
      &__toggle-consent{
          display: flex;
          flex-direction: row;
          margin-top: 10px;
      }
      &__text-and-pic{
        display: flex;
        flex-direction: row;
        margin:20px 0;
        justify-content: space-between;
      }
      &__half-section{
        width:47%;

      }
      &__delete-icon{
        width:16px;
        height:16px;
        cursor: pointer;
      }
      &__two-inputs{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &__textarea{
        height:auto;
      }

      &__select {
        width: 20%;
      }

      &__row {
        display: flex;
        justify-content: space-between;
      }

    @include tablet{

    }
    @include desktop{

    }
}
.add-modal  ::-webkit-scrollbar {
  width: 10px;
}
.add-modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
