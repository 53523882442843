.long-invitation-modal {
  overflow: auto;

  .homepage__answer {
    margin-top: 0;

    .standard__link h3 {
      line-height: 20px;
    }
  }
}
