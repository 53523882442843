@import '../../resources/media-queries.scss';

.login{
    position: relative;
    min-height: 100vh;
    &__title{
        font-size: 24px;
        color: #000;
        padding-bottom: 15px;
    }
    &__container{
        padding:20px;
    }
    &__lateral{
        display: none;
    }
    &__form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
             transition: background-color 5000s ease-in-out 0s;

 }
    }
    &__subtitle{
        font-family: OpenSans;
        font-size:14px;
        font-weight: 600;
        color:#6e6e6e;;
        span{
            color: #e66300;
        }

    }
    &__label{
        font-family: OpenSans!important;
        font-size: 16px;
        color: #000;
        padding:25px 0 5px;
    }
    &__input{
        background: #FFFFFF;
        height:36px;
        border-radius: 3px;
        border: solid 1px #ccc;
    }
    &__input:focus{
        outline:none;
    }
    &__submit-button{
        width: 88px;
        height: 38px;
        border-radius: 5px;
        background-color: #e66300;
        border:none;
        margin-top: 30px;
        width:100%;

        a {
          text-decoration: none;
        }
    }
    &__button-writing{
        color: #F6F7FA;
        font-size: 16px;
        line-height: 20px;
    }
    &__colored{
        color:#e66300;
    }
    &__option-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:40px 0 20px;
    }
    &__line{
        background-color: #000000;
        width:60px;
        height:1px;
        margin-top: 10px;
    }
    &__option-writing{
        font-size: 14px;
    }
    &__option-login{
        background:transparent;
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        padding:10px 0;
        margin:20px 0;
        border:1px solid #000000;
        img{
            width:20px;
            margin-right: 5px;
        }
        h5{
            font-size: 14px;
        }
    }
    &__see-password{
        display: flex;
        flex-direction: row;
        input{
            width:90%;
        }
        img{
            width:20px;
            height:20px;
            margin-top: 10px;
            cursor: pointer;
            margin-left: 10px;
        }
    }
    &__forgot-password{
        font-family: OpenSans;
        font-size:12px;
        font-weight: 600;
        padding-top: 15px;
        color:#6e6e6e;;
        span{
            color: #e66300;
        }
    }
    &__password-input{
        width:100%!important;
    }
    @include tablet{
        &__house-img{
            object-fit: cover;
        }
        &__container{
            width:78%;
            padding:40px;
        }
        &__form{
            width:80%;
            margin:0 auto;
        }
        &__label{
            font-size: 16px;
        }
    }
    @include desktop{
        &__wrapper{
            display: flex;
            flex-direction: row;
        }
        &__lateral{
            display: block;
            position: absolute;
            top:0;
            width:30%;
            z-index:1;
            height:110vh;
        }
        &__logo{
            padding-bottom: 40px;
        }
        &__option-login{
            width:65%;
            margin:20px auto;
        }
        &__container{
          padding:40px;
          width:80%;
          margin-left: 20%;
          margin-bottom:200px;

        }
        &__submit-button{
            width:65%;
            height:48px;
            margin:50px auto ;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__option-box{
            margin: 40px auto 20px;
            width: 65%;
        }
        &__line{
            width:120px;
        }
        &__subtitle, &__title{
            text-align: left;
            padding-left: 25%;
        }
        &__input{
            height:48px;
        }
        &__form{
            margin:20px 25% 0;
        }
        &__label{
            font-size: 18px;
        }
        &__password-question{
            display: flex;
            justify-content: flex-end;
        }
    }
}
