@import '../../../resources/media-queries.scss';
.budget{
    &__paid-cell{
        display: flex;
        flex-direction: row;
        img{
            margin-left: 5px;
            position: relative;
            bottom:3px;
            height: 20px;
            width: 20px;
        }
    }
    &__table-container{
        justify-content: wrap;
        overflow-x: scroll;
        position: relative;
        top:20px;
    }
    &__wrapper{
      width:250%;
    }
    &__cell{
        h4{
            font-weight: 500!important;
        }
    }
    &__orange-line{
        height:2px;
        width:100%;
        margin:40px 0 20px;
        background-color:#e66300 ;
        background-image: linear-gradient(173deg, rgba(255, 244, 229, 0) 16%  #f7ebd7 ,49%);
    }
    &__box{
        display: flex;
        flex-direction: row;
        margin:15px 0;
        justify-content: space-between;
        width:80%;
    }
    &__general-info{
        font-size: 14px;
        color: #48525D;
    }
    &__specific-info{
        font-size: 14px;
        color: #e66300;
    }
    &__total{
        position: sticky;
        background-color: #fff;
        bottom:0;
        box-shadow: 6px 4px 14px 3px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding:1px 10px;

    }
    &__container{
        position:relative;
    }

    @include tablet{

    }
    @include desktop{
        &__wrapper{
         width: 100%;
          }
          &__actions{
              flex-direction: left!important;
              position: relative;
              right:30px;
          }
          &__table-container{
              overflow: hidden;
          }
          &__box{
              width:20%;
              justify-content: flex-start;
          }
          &__general-info{
            padding-right: 5px;
          }
          &__total{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          &__container{
            width:85%;
          }
    }
}
