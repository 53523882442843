@import '../../resources/media-queries.scss';

.success{
    &__close-icon{
        width:20px;
        position: relative;
        left:95%;
        cursor: pointer;
    }
    &__logo{
        margin:20px auto;
        display: flex;
        justify-content: center;
    }
    &__title{
        font-size: 22px;
        text-align: center;
        padding:40px 0;
    }
    &__text{
        text-align: center;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:0 auto 40px!important;

    }
    @include tablet{

    }
    @include desktop{
        &__close-icon{
         
        } 
    }
}