@import '../../../resources/media-queries.scss';

.invitation{
    font-family: 'Roboto';
    &__wrapper{
        padding:20px;
    }
    &__initials{
        padding:10px;
        border-radius: 50%;
        width:20px;
        height:20px;
        display: flex;
        justify-content: center;
    }
    &__tool-icon{
        width: 18px;
        height: 18px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 6px;
        margin-right: 20px;
        cursor: pointer;

    }
    &__search-field{
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #e66300;
        height:48px;
        width:100%;
        text-indent: 40px;
        z-index: 10;
        position: relative;
        background:transparent;
    }
    &__search-section{
        position: relative;
    }
    &__search-icon{
        width:24px;
        position: absolute;
        height:24px;
        left:10px;
        top:15px;
        z-index: 20;
    }
    &__add-guest{
        width:100%;
        height:48px;
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #e66300;
        border:none;
        color:#fff;
        font-family: Roboto;
        font-size: 12px;
        position: relative;
        z-index: 10;
        margin:20px 20px 20px 0;
    }
    &__content-wrapper{
        margin:20px 0;
    }
    &__guest{
        border-radius: 8px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin:15px 0;
        padding:20px;
        h4{
            font-family: Roboto;
            font-weight: 500;
            font-size: 14px;
            padding-bottom: 10px;
        }

    }
    &__guest-top{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__guest-confirmation{
        display: flex;
        flex-direction: row;
        position: relative;
        top:10px;
        img{
            width:16px;
            height:16px;
            position: relative;
            top:-10px;
            left:10px;
        }
    }
    .red{
        border:1px solid red;
        border-radius: 22px;
        height:23px;
        padding:5px 10px;
        width:100px;
        text-align: center;
        display: flex;
        align-items: center;

        justify-content: center;
    }
    .green{
        border: 1px solid #2b740a;
        border-radius: 22px;
        height:23px;
        padding:5px 10px;
        width:100px;
        text-align: center;
        display: flex;
        align-items: center;

        justify-content: center;
    }

    &__actions{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        width:80%;
        position: relative;
        top:10px;
        color: #e66300;
    }
    &__search-form{
        position: relative;
    }
    &__link{
        color: #e66300;
        width:50%;
    }
    @include tablet{
        &__wrapper{
            padding:40px;
        }
    }
    @include desktop{
        &__content-wrapper{
            display: none;
        }
        &__top-desktop{
           display: flex;
           flex-direction: row;
           justify-content: space-between;
            margin-top: 20px;
        }
        &__top-right{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }
        &__add-guest{
            width:170px;
            margin-top: 0;
        }
        &__search-button{
            border-radius: 12px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px #e66300;
            cursor: pointer;
            width:48px;
            display: flex;
            justify-content: center;
            align-items: center;
            height:44px;
            margin-right: 30px;
            margin-bottom: 20px;

            img{
                width:24px;
                height:24px;
            }
        }
        &__search-form{
            position: relative;
            width:350px;
            margin-right: 30px;
            margin-bottom: 20px;
            input:focus{
                outline:none;
            }
        }

        &__close-search{
            width:20px;
            height:20px;
            cursor: pointer;
            position: absolute;
            z-index: 100;
            right:0;
            top:15px;
        }
        &__wrapper{
            padding: 40px 0 0 60px;
        }
        .red, .green{
            width:50%;
        }
        &__guests-table{
            margin-top: 40px;
        }
        &__special-close{
            bottom:30px;
        }
        &__add-category{
            background-color: white;
            color:black;
            border: solid 1px #e66300;
            margin-right: 30px;
        }

    }
}
