@import '../../resources/media-queries.scss';

.preorder{

  &__input {
    width: 98%;
  }
  &__main-title{
      font-size: 20px!important;
  }
    &__title{
        font-size: 14px;
        color:#000;
        font-weight: lighter;
        padding-bottom: 15px;
    }
    @include desktop{
        &__input{
            width:60%;
        }
        &__main-title{
            font-size: 26px!important;
            width:40%!important;
        }
        &__button{
            margin-left: 0;
            width:60%;
        }
        &__title{
            font-size: 18px;
            padding-left: 25%;
        }
        &__lateral{

        }
    }
}
