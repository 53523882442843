@import '../../resources/media-queries.scss';
.charity{
    &__step{
        width:75px;
        height:75px;
        h3{
            font-size: 20px;
        }
    }
    &__form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:20px auto;
        width:100%;
    }
    &__label{
        font-family: OpenSans!important;
        font-size: 16px;
        color: #000;
        padding:10px 0 5px;
    }
    &__field-box{
        margin:10px auto;
        width:80%;
    }
    &__field{
        background: #FFFFFF;
        border:none;
        border-bottom: 1px solid #e66300;;
        border-radius: 4px;
        height:44px;
        display: flex;
        justify-content: center;
        margin:0 auto;
        width:100%;
    }
    &__submit{
        width: 88px;
        height: 38px;
        border-radius: 5px;
        background-color: #e66300;
        border:none;
        margin-top: 30px;
        width:60%;
        color: #F6F7FA;
        margin:20px auto;
        font-size: 16px;
        line-height: 20px;
    }
    &__form-title{
        text-align: center;
        font-size: 22px;
        padding-top: 30px;
        padding:40px 20px;
    }
    @include tablet{
        
    }
    @include desktop{
        &__form{
            width:70%;
            margin:40px auto;
        }
        &__line{
            margin:0 auto;
            width:150px;
            height:3px;
        }
    }
}