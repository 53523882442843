

    .guests-types{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        div{
            width:50%;
        }

        [type=radio] {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
          cursor: pointer;
        }


        [type=radio] + img {
          cursor: pointer;
        }


        /* CHECKED STYLES */
        [type=radio]:checked + img {
          border: 2px solid #f00;
        }
        }
        .guest-type-picked{
          border:1px solid #e66300;
          padding-bottom: 5px;
          border-radius: 5px;
        }
        .guest-label{
          text-align: center;
          font-size: 12px;
          padding-top: 10px;
        }

        .guests-types, .guest-type-picked {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
