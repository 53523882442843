@import '../../../resources/media-queries.scss';

.provider {
    &__providers-wrapper {
        width: 150%;
    }

    &__full-wrapper {
        justify-content: wrap;
        overflow-x: scroll;
        position: relative;
        top: 20px;
    }
    &__description-box{
        white-space: nowrap;
        overflow: hidden;
        position:relative;
        text-overflow: ellipsis;
    }
    &__description {
        
    }
}