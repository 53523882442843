@import '../../../resources/media-queries.scss';

.registry{
    &__table-container{
        justify-content: wrap;
        overflow-x: scroll;
        position: relative;
        top:20px;
    }
    &__wrapper{
      width:250%;
    }
    &__link{
        h4{
            color:darkblue;
        }
       
    }
    @include desktop{
        &__table-container{
            overflow: hidden;
        }
        &__wrapper{
            width:100%;
        }
        &__container{
            width:80%!important;
        }
    }
}