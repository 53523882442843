@import '../../resources/media-queries.scss';

.admin{
    h1, h2, h3, h4, h5, li{
        margin:0;
        font-family: Roboto!important;

    }
    button{
        cursor: pointer;
    }
    &__budget-form-wrapper{


        div{

        }
    }
    &__add-budget{
        width: 100%;
    height: 48px;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    color: #e66300;
    border:1px solid #e66300!important;
    background:#fff;
    border: none;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    position: relative;
    z-index: 10;
    margin: 20px 0;
    }
    &__budget-form{
        position:relative;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        div{
            width:40%;
        }
    }
    &__top{
        box-shadow: 2px 14px 14px -10px rgba(0,0,0,0.14);
        padding:20px 40px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        box-shadow: 5px 2px 15px -3px rgba(0,0,0,0.27);
        position: relative;
        z-index: 5;
        background-color: #fff;
    }
    &__logo-icon{
        width:40px!important;
        height:40px!important;
        border-radius: 3px;
        object-fit: cover;
    }
    &__container{
        width:100%;
    }
    &__shadow{
        position: relative;
    }
    &__shadow-img{
        position: absolute;
        min-height: 100vh;
        width:100%;
        top:0;
        opacity: 0.6;
    }
    &__soon-text{
        position: absolute;
        top:25%;
        font-size: 48px;
        left:15%;
        width:50%;
        font-family: 'Playfair Display', serif!important;
        text-align: center;
        line-height: 1.4;
        font-weight: 400;
        z-index: 10;
        padding:40px 80px;
        border-radius: 12px;
        background-image: url("/assets/basic-gradient.png");
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-repeat: no-repeat;
         background-size: cover;

    }

    &__select-box{


    }
    &__select{
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        height:48px;
        right:0;
        border:none;
        padding:10px 40px;
    }
    &__select:focus{
        outline:none;
    }
    &__couple-icon{
        width:48px;
        height:48px;
        object-fit: cover;
        border-radius: 10px;
        margin-left: 20px;
    }
    &__title{
        font-size: 18px;
        font-weight: bold;
        font-family: Roboto!important;
    }
    &__action-container{
        margin-top: 40px;
        padding:0 20px;
    }
    &__preview{
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #e66300;
        color:#fff;
        height:48px;
        border:none;
        width: 150px;
        margin: 0 10px;
    }
    .MuiTab-wrapper{
        font-family: Roboto;
        font-size: 14px;
        text-transform: capitalize;
    }
    .Mui-selected button{
        border:none;
        background-color:green!important;
    }
    .MuiTabs-flexContainer{
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
    .only-desktop{
        display: none;
    }
    &__search-field{

    }
    &__no-something{
        display: flex;
        flex-direction: row;
        margin:15px 0;
        img{
            width:46px;
            height:46px;
            object-fit: contain;

        }
        h5{
            font-size: 14px;
            font-weight: 500;
            position: relative;
            top:10px;
        }
    }
    &__filter-icon{
        width:18px;
        height:18px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #e66300;
        cursor: pointer;
        margin-top: 20px;
        padding:8px;
    }
    &__guest-top{
        display: flex;
        flex-direction: row;
        margin:20px 0;
        img{
            border-radius: 12px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
            width:20px;
            height:20px;
            padding:10px;
            margin-right:10px;
        }
    }
    &__guest-type-icon{
        width:96px;
        height:96px;
    }
    &__guest-icon-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__guest-info-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:20px 0;
    }

    &__guest-info-box--small {
      width: 45%;
    }
    &__info-specific{
        font-weight: 500;

    }
    &__divider{
        width:100%;
        height:1px;
        background: rgba(0, 0, 0, 0.1);
        margin:20px 0;
    }
    &__table-config-avertisment{
        position: relative;
        top:50px;
        font-family: 'Playfair Display', serif!important;
        text-align: center;
        line-height: 1.4;
        font-weight: 400;
        z-index: 10;
        padding:40px;
        border-radius: 12px;
        background-image: url("/assets/basic-gradient.png");
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-repeat: no-repeat;
         background-size: cover;
         h3{
             font-size: 20px;
             padding-bottom: 10px;
         }
    }
    @include tablet{

    }
    @include desktop{
        display: flex;
        flex-direction: row;
        &__action-container{
            padding:0;
        }
        &__tool-box{
            position: relative;
        }
        &__additional-info{

            cursor: pointer;
        }
        &__guest-top{
            h2{
                position: relative;
                top:10px;

            }
        }
        &__guest-container{
            display: flex;
            margin-top: 30px;
            flex-direction: row;
        }
        &__guest-icon-box{
          justify-content: initial;
          margin-right: 50px;
        }
        &__guest-info{
            width:40%;
        }
        &__subtitle{
            padding-bottom: 20px;
        }
        .only-mobile{
            display: none;

        }
        .only-desktop{
            display: block;
        }
        &__wrapper{
            width:85%;
            padding: 80px 0 0 60px;
        }
        &__top{
            padding-right: 10%;
            height:84px;
            box-shadow: 2px 14px 14px -10px rgba(0,0,0,0.14);
        }
        &__couple-icon{
            margin-left: 40px;
            margin-top: 20px;
        }
        &__select-box{
            margin-top: 20px;

        }
        .MuiTabs-root {
            margin-left: -30px;
        }
        &__wrapper{
            padding-top: 40px;

        }
        &__budget-form-wapper{
            width:40%;
        }
        &__budget-form{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: nowrap;
            width:40%;
            min-width: 600px;
            div{
                width:30%;
            }
        }
        &__add-budget{
            width:170px;
            margin-top: 40px;
            border:1px solid transparent!important;
        }

        &__filter-form-wrapper {
          width: 100%;
        }

        &__filter-form {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          div {
            width: 12%;
          }

          &--left-align {
            justify-content: flex-start;
            gap: 20px;
          }
        }

        &__filter-form-invite {
          div {
            width: 20%;
          }
        }
    }
}
