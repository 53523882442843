@import '../../resources/media-queries.scss';

.footer{
    padding:20px 0;

    background-image: url('/assets/bottom.png');
    background-repeat: no-repeat;
    width:100%;
    background-size:cover;
    position: relative;
    &__container{
        display: flex;
        flex-direction: column;

    }
    &__column{
        text-align: center;
        h3{
            font-size: 20px;
            font-weight: bold;
            padding: 10px;

        }
        h4{
            font-size: 14px;
            padding:5px 0;
        }
    }
    &__logo{
        width: 150px;
        height: 60px;

    }
    &__social-box{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin:10px 0;
        img{
            width:20px;
            height:20px;
            position: relative;
            top:3px;
            right:5px;
        }
    }
    &__copyright{
        padding-top: 30px;
        text-align: center;
    }
    @include tablet{

    }
    @include desktop{
        width:100%;
        background-size:cover;
        z-index:4;
        padding-top: 40px;
        &__logo{
            width:175px;
            height:65px;
            margin-bottom: 20px;
        }
        &__container{
            flex-direction: row;
            justify-content: center;

        }
        &__column{
            width:100%;
            h3{
                padding:20px 0 20px;

            }
            h4{
                font-size: 16px;

            }
        }
        &__copyright{
            padding-top: 20px;
        }
    }
}
