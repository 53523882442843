@import '../../resources/media-queries.scss';

.payment{
    &__container{
        margin:40px 0;
    }
    &__title{
        font-size: 28px;
    }
    &__subtitle{
        font-size: 20px;
    }
    @include desktop{
        &__container{
            width:60%;
            margin-left: 20%;
            margin-bottom: 20%;;
        }
        &__title{
            font-size: 34px;
        }
        &__subtitle{
            font-size: 24px;
            padding-top: 40px;
        }
        &__contact-box{
            align-items: initial;
            margin-left: 30%;
        }
        &__contact-div{
            height:30px;
        }
    }
}