@import '../../resources/media-queries.scss';

.form{
    &__header{
        padding:30px 20px 10px;
    }
    &__container{

    }
    &__banner-img{
        width:100%;
        height:140px;
        object-fit: cover;
    }
    &__banner{
        position: relative;
        h3{
            font-size: 16px;
            line-height: 20px;
            color:#fff;
            position: absolute;
            bottom:40px;
            left:20px;
        }
    }

    &__general-message {
      padding-bottom: 20px!important;
    }

    &__checkbox-label{
        font-size: 14px;
        line-height: 16px;
        color: #697B8A;
        position: relative;
        top:2px;
    }
    &__checkbox-container{
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 90%;
        border-radius: 50%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 22px;
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.7);
        margin:10px 0 15px;
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }

    // &__priority-checkbox-container--disabled {
    //   color: grey;
    // }

    &__container:hover input ~ &__checkmark{}
    &__checkbox-container input:checked ~ &__checkmark{
        background:transparent;
        border:1px solid #697B8A;
        border-radius: 50%;
    }
    &__checkmark{
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color:transparent;
        padding:2px;
        border:1px solid #697B8A;
    }
    &__checkmark:after{
        content: "";
        position: absolute;
        display: none;
    }
    &__checkbox-container input:checked ~ &__checkmark:after{
        display: block;

    }
    &__checkbox-container &__checkmark:after{
        left: 2px;
        top: 2px;
        width: 10px;
        height: 10px;
        border: solid #1992D4;
        background:#1992D4;
        border-radius: 50%;
    }
    &__wrapper{
        padding:20px;
    }
    &__label-name{
        padding:10px 0 5px;
        font-size: 14px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: bolder;
    }
    &__field{
        margin:10px 0;
        background: #FFFFFF;
        border: 1px solid #A3D3EE;
        border-radius: 4px;
        height:40px;
        width:100%;
        font-size: 14px;
    }
    &__field:focus{
        outline:none;
    }
    &__box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    &__subtitle{
        font-size: 14px;
        line-height: 17px;
        color: #1992D4;
        padding:15px 0 10px;
    }
    &__textarea{
        height:auto;
    }
    &__bottom{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:10px 0;
        align-items: center;

        img{
            width:36px;
            height:16px;
            position: relative;
        }
        button{
            background: #1992D4;
            border-radius: 4px;
            border:none;
            height:40px;
            width:146px;
            color:#fff;
            font-size: 12px;
        }
        button:hover{
            background-color: #136ea0;
        }
    }

    &__add-to-calendar {
      .atcb-button {
        height: 10px;
      }
    }

    @include tablet{
        &__header{
            padding:30px 40px;
        }
        &__wrapper{
            padding:30px 40px;
            width:60%;
        }
    }
    @include desktop{
        &__header{
            padding:30px 10%;
        }
        &__banner-icon{
            width:130px;
            height:120px;
            position: absolute;
            bottom:93%;
            opacity:0.7;

        }
        &__banner-icon-2{
            width:140px;
            height:130px;
            position: absolute;
            bottom:-40px;
            opacity:0.6;
            right:0;
        }
        &__banner-img{
            height: 400px;
        }
        &__banner h3{
            left:50%;
            transform: translate(-50%, -50%);
            font-size: 22px;
            bottom:100px;
            text-align: center;
        }
        &__wrapper{
            padding:40px 30%;
        }
        &__form-icon{
            width:270px;
            height:225px;
            position: absolute;
            opacity: 0.4;
            left:70px;
            top:70%;
        }
        &__form-icon-2{
            width:270px;
            height:225px;
            position: absolute;
            right:40px;
            opacity: 0.4;
            top:80%;
        }
    }
}
