@import '../../resources/media-queries.scss';

.account {
    &__couple {
        input {
            width: 100%;
        }
    }

    &__checkbox-div {
        display: flex !important;
        flex-direction: row !important;
        margin: 10px 0;
        position: relative;

        h4 {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.18;
            position: relative;
            left: 30px;
            top: 15px;
            font-weight: bold;
        }
    }

    &__checkbox-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__checkbox-custom,
    &__checkbox-label {
        position: absolute;
        top: 18px;
        left: 2px;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 3px;
        border: solid 1px #e66300;
    }

    &__checkbox-custom,
    &__checkbox-label::after {
        position: absolute;
        content: "";
        left: 12px;
        top: 12px;
        height: 0px;
        width: 0px;
        border: solid 1px #e66300;
        opacity: 1;
        -webkit-transform: rotate(0deg) scale(0);
        -ms-transform: rotate(0deg) scale(0);
        transform: rotate(0deg) scale(0);
    }

    &__checkbox-label input:checked~&__checkbox-custom {
        background-color:
        #e66300;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
        width: 10px;
        height: 10px;
        top: 2px;
        left: 2px;
        border: solid 2px #e66300;
    }

    &__checkbox-label input:checked~&__checkbox-custom::after {
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        opacity: 1;
        border: solid #e66300;
        border-width: 0 2px 2px 0;
        left: 8px;
        top: 8px;
        width: 6px;
        height: 12px;
        background-color: transparent;
        border-radius: 0;
    }

    .create-acount-image {
        background: #FFFFFF;
        height: 80px;
        width:100%;
        border-radius: 3px;
        border: solid 1px #ccc;
    }
    &__password-box{
       position: relative;
    }
    &__eye{
        width:20px;
        height:20px;
        position: absolute;
        right:10px;
        bottom:10px;
        cursor: pointer;
        z-index: 54;
      
    }

    @include tablet {}

    @include desktop {
        &__couple {
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-direction: row;

            div {
                width: 45%;
            }

            input {
                width: 100%;
            }
        }

        &__container {
            width: 100%;
        }

        &__lateral {
            height: 150vh;
        }
        &__checkbox-div { 
            h4 {
                top: 20px;
            }
        }
    }
}