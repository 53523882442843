@import '../../resources/media-queries.scss';

.homepage{
    min-height: 100vh;
    background-image: url('/assets/bubbles.png');
    background-repeat: repeat;
    width:100%;
    background-size: cover;
    background-position: center;

    &__video-wrapper {
      width:100%;
      position: relative!important;
      margin-left: 0;
      height: 300px;
    }

    &__general-video {
      padding-bottom: 40px;
    }

    &__gift-message{
        flex-direction: column!important;
        align-items: center;
        h5{
            padding:10px 0;
        }
    }
    &__flags{
        display: flex;
        flex-direction: row;
        img{
            width:30px!important;
            height:20px!important;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    &__daian-steps{
        display: flex;
        flex-direction: row;
    }
    &__daian-arrow{
        height:30px;
        margin:5px 0;
    }
    &__daian-writing{
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
    }
    &__daian-writing-2{
        position: relative;
        top:10px;
    }
    &__daian-invitation{
        width:100%;
        height:350px;
    }
    &__container{
        padding:30px 20px;
    }
    &__question-img{
        width: 100%;
        height: 220px;
        margin-top: 20px;
    }
    &__simple-story-img {
      width: 100%;
      height: 350px;
      object-fit: contain;
      padding-bottom: 30px;
    }
    &__story-1{
        padding-left: 20px;
    }
    &__gallery-name{
        display: flex;
        flex-direction: row;
        a{
            padding-right: 3px;
        }
    }
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img{
            width:56px;
            height:40px;
            position: relative;
            bottom:4px;

        }
    }
    &__gallery{
        position: relative;
        bottom:12px;
        font-size: 12px!important;
    }
    &__names{
        font-weight: lighter;
        font-size: 26px;
        line-height: 32px;

    }
    &__story-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        h4{
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.65);
            text-align: center;
            padding-top: 5px;
        }
    }
    &__date{
        font-size: 14px;
        line-height: 14px;
        color: #000000;
        padding-top: 10px;
        text-transform: capitalize;
    }
    &__story{
        margin:40px 0 0;
    }
    &__story-photos{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__story-img{
        width:100px;
        height:100px;
        border-radius: 50%;
        margin:0 25px;
        object-fit: cover;

    }
    &__story-arrows{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__arrow{
        width:40px;
        height:50px;
        margin:0 10px;


    }
    &__story-2 img{
        margin:10px auto;
        display: flex;
    }
    &__arrow-3{
        height: 80px;
    width: 62px;
    position: relative;
    left: 50px;
    bottom: 4px;
        bottom:10px;
    }
    &__story-3{
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: space-between;
        bottom:15px;
        width:100%;
        img{
            margin:0;
        }
    }
    &__arrow-4{
        width: 145px;
        height: 13px;
        position: relative;
        top: 50px;
    }
    &__arrow-6{
        position: relative;
    left: 60%;
    height: 42px;
    width: 65px;
    bottom: 175px;
    }
    &__invitation{
        position: relative;
        width:100%;
    }

    &__invitation-text {
      position: absolute;
      bottom:70px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      left: 0;
      right: 0;

      h4{
          text-align: center;
          margin:0 auto!important;
          display: flex;
          justify-content: center;
          font-weight: bolder;
          color: #FFFFFF;
          padding:2px 0;
          text-shadow: 7px 4px 8px black;
      }

      .form__bottom {
        justify-content: center;
      }
    }
    &__invitation-img{
        width:100%;
        height:205px;
        object-fit: cover;
        position: relative;
        bottom:20px;

    }
    &__location-info{
        margin:40px 0;
        border-radius: 5px;
        background: #D1E9F7;
    }
    .MuiTab-wrapper{
        text-transform: capitalize;
        width:76px;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        color:#4D4D4D;
        font-weight: bold;
        border-right: 1px solid #fff;
        padding-right: 4px;
    }
    .Mui-selected{
        background-color:#fff;
        width:100px;
        color:#1992D4!important;


    }
    .MuiTabs-root{
        padding:5px 3px 0 5px;
    }
    &__location{
        background:#fff;
        padding:30px 10px 0;
        margin:0 3px 3px 6px;
    }
    &__location-title{
        font-size: 14px;
        line-height: 15px;
        color: #000000;
        padding-bottom: 10px;
    }
    &__location-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__location-box{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        img{
            widows: 18px;
            height:18px;
            margin-right: 5px;
        }
        h5{
            font-size: 12px;
            line-height: 15px;
            color: #000000;
        }
    }
    &__see{
        background: #1992D4;
        border-radius: 4px;
        width:100px;
        height:26px;
        border:none;
        color:#fff;
        font-size: 12px;
        margin-left: 10px;
    }
    &__ask{
        margin:20px 0;
    }
    &__title-box{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__title{
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #000000;
        z-index:5;
    }
    &__smudge{
        height:10px;
        background-color:  #D1E9F7;
        width:50%;
        margin:0 auto;
        position: absolute;
        bottom: 90px;
        left: 25%;
        opacity: 0.5;
    }
    &__answer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin:15px 0;
        h3{
            padding:10px 15px;
            font-size: 36px;
            line-height: 44px;
            font-weight: lighter;
            cursor: pointer;
        }
        h3:hover, h3:focus{
            border:1px solid #1992D4;
            border-radius: 50%;
        }


    }
    &__smudge-2{
        bottom:0;
        width:50%;
        left:25%;
    }
    &__contact{

    }
    &__info{
        font-size: 14px;
        line-height: 15px;
        align-items: center;
        text-align: center;
        width:80%;
        display: flex;
        justify-content: center;
        margin:0 auto!important;
        color: #000000;
        padding:15px 0 5px;
    }
    &__contact-wrapper{
        display: flex;
        flex-direction: row;
        margin:20px auto;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    &__contact-box{
      width: 45%;

        div{
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;

            img{
                width:16px;
                height:16px;
                margin-right: 5px;
            }
            h4{
                font-size: 14px;
                line-height: 15px;
                color: #000000;
            }
        }
    }
    &__answer-box{
        display: flex;
        flex-direction: row;
        width:100%;
        margin:14px auto;
        justify-content: center;
        input[type="radio"] {
            opacity: 0;
            position: fixed;
            width: 0;
          }
    }
    &__answer-box label{
        padding:0 15px;
        font-size: 36px;
        line-height: 44px;
        font-weight: lighter;
        font-family: 'Playfair Display', serif;
    }
    &__answer-box input[type="radio"]:checked + label {
        border:1px solid #1992D4;
        border-radius: 50%;
        padding:8px 10px;
        color: #1992D4;

    }
    &__answer-box input[type="radio"]:focus + label {
        border:1px solid #1992D4;
        border-radius: 50%;
        color: #1992D4;
    }
    &__answer-box input[type="radio"]:hover + label {
        border:1px solid #1992D4;
        border-radius: 50%;
        color: #1992D4;
    }
    &__countdown-box{
        display: flex;
        flex-direction: column;
        margin: 20px 0 40px;
        justify-content: center;
        align-items: center;
        color:#1992D4;
        font-size: 38px;

        // span {
        //   font-size: 30px;
        //   font-weight: 600;
        //   font-stretch: normal;
        //   font-style: normal;
        //   line-height: normal;
        //   letter-spacing: normal;
        //   color: #ffffff;
        //   padding-left: 10px;
        // }
    }

    &__hidden {
      display: none;
    }
    &__countdown-name{
        display: flex;
        flex-direction: row;
        font-size: 10px;
        color:black;
        justify-content: space-between;
        width:90%;
        padding-top:10px;
        h4:first-of-type{
            width:22%;
            position: relative;
            left:30px;
        }
        h4:nth-of-type(2){
            position: relative;
            left:20px;
        }
        h4:nth-of-type(3){
            position: relative;
            left:10px;
        }
        h4:last-of-type{
            position: relative;

        }
    }
    &__location-wrapper{
        display: flex;
        flex-direction: column;
    }
    &__google-mobile-location{
        .google-location {
          height: 200px;
          position: relative;
        }
    }
    .rotate {
        animation: rotate-keyframes 1s;
       }
       @keyframes rotate-keyframes {
        from {
         transform: rotate(0deg);
        }

        to {
         transform: rotate(180deg);
        }
       }

       &__story-title-box{
           padding-top: 20px;
           display: flex;
           flex-direction: column;
           justify-content: center;
           h2, h3{
               text-align: center;
           }
           h2{
            font-weight: lighter;
           }
           h3{
            color: rgba(0, 0, 0, 0.65);
            font-size: 12px;
           }
       }
       &__invitation-title{
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        position: relative;
       bottom:35px;
       }
       &__last-arrow{
           height:45px;
           display: flex;
           justify-content: center;
           margin:0 auto 10px;
           position: relative;
           bottom:30px;
       }
       &__spot-story{
           display: flex;
           align-items: center;
           margin-top: 15px;
           h4{
               width:60%;
           }
       }
       &__story-box-4{
        align-items: flex-start;
        h4{
          text-align: left;
        }
       }
       &__story-box-5{
        align-items: flex-end;
        h4{
            text-align: right;
        }

       }
       &__arrow-7{
        position: relative;
       bottom:42px;
       height:30px;
       }
       &__small-detail{
        font-size: 12px;
        padding-bottom: 10px;
       }
       &__unknown-location{
        display: none!important;
       }
    @include tablet{
        &__container{
            padding:30px 40px;
        }
        &__story-3{
            margin-left: 15%;
        }
        &__arrow-3{
            left:30%;
        }
        &__arrow-4{
            width:35px;
        }
        &__arrow-6{
            bottom:150px;
        }
        &__invitation-img{
            height:300px;
        }
        &__invitation{
            bottom:25px;
        }
        &__invitation-title{
            bottom:38px;
        }
        &__invitation div{
            flex-direction: row;
            margin-left: 39%;
            h4{
                padding-right: 5px;
            }
        }
        &__location{
            padding:30px 95px;
            width:100%;
        }
        &__contact-wrapper{
            width:50%;
            margin-left: 25%;
        }
        &__story-1{
            justify-content: space-evenly;
            width:45%;
            margin-left: 200px;
            padding-left: 0;
        }
        &__story-3{
            width:45%;
            margin-left: 200px;
        }
        &__story-arrows{
            padding:10px 20px 10px 0;
        }
        &__arrow-6{
            left:57%;
        }

        &__story-2 {
          display: flex;
          justify-content: center;
        }
    }

    @include desktop{
      &__video-wrapper {
        height: 500px;
      }

      &__general-video {
        padding-bottom: 0;
      }

      &__gift-message{
        flex-direction: row!important;
        align-items: initial;
        h5{
            padding:initial;
        }
    }
      &__flags{
        position: absolute;
        right:40px;
        top:40px;
    }

        &__daian-steps{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: 20%;
            h4{
                width:100%!important;
            }
        }
        &__daian-story-2{
            flex-direction: column;
            margin:10px auto!important;
            width:100%!important;
            align-items: center;
        }
        &__daian-invitation{
            height:600px;
            object-fit: cover;
            align-content: center;
            position: relative;
            top:20px;
        }
        &__unknown-location{
            display: flex!important;
            flex-direction: row;
            justify-content: space-between;
            width:70%;
            margin-left: 20%;
            h4{
                padding-top: 80px;
            }
        }
        &__question-img{
            height:350px;
            position: relative;
            left:15%;
            border-radius: 5px;
        }
        background-image: url('/assets/confetti.jpg');

        &__container{
            padding:40px 10%;
        }
        &__header-info{
            display: flex;
            flex-direction: row;

        }
        &__date-first{
            padding-right: 40px;
        }
        &__first-section{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
        }
        &__story{
            width:50%;
        }

        &__simple-story-img {
          padding-bottom: 0;
        }
        &__time-details{
            width:50%;
            margin-top: 50px;
        }
        &__location-desktop-img{
            width:50%;
        }
        &__time-info{
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            padding-bottom: 20px;
            text-transform: capitalize;
        }
        &__smudge{
            left:30%;
            width:40%;
            bottom:132px;
        }
        &__story-img{
            width:150px;
            height:150px;
        }
        &__arrow-3{
            height:80px;
            left:25%;

        }
        &__story-3{
            width:100%;
        }
        &__arrow-6{
            bottom:0;
            height:90px;
            left:68%;
            height:50px;
        }
        &__arrow-4{
            width:115px;
            top:70px;
        }
        &__answer{
            margin-top: 60px;
        }
        &__invitation-img{
            height:500px;
        }
        &__contact-wrapper{
            width: 75%;
        }
        &__smudge-2{
            bottom:-2px;
            left:42%;
            width:15%;
        }
        &__location-info{
            background:transparent;
        }
        &__tab-icon{
            display: none;
        }
        .MuiTab-wrapper{
            text-transform: capitalize;
            width:auto;
            padding:5px 20px 5px 10px;
            font-size: 12px;
            font-family: 'Montserrat', sans-serif;
            color:#4D4D4D;
            font-weight: bold;
            border-right: none;
            padding-right: 4px;
            background-color:#fff;
            border: 2px solid #A3D3EE;
            border-radius: 20px;
        }
        .Mui-selected{
            width:auto;
            border-left: none;
            border-bottom: 1px solid #A3D3EE;
            background-color: transparent;
        }
        .MuiTabs-root{
            padding:5px 0 0;
        }
        &__see{
            display: none;
        }
        &__location-smaller-wrapper{
            display: flex;
            flex-direction: row;
        }
        &__location{
            width:50%;
        }
        &__location-wrapper{
            margin-top: 80px;
            display: flex;
            flex-direction: row;
        }
        &__invitation{
           cursor: pointer;
        }

        &__invitation-text {
          flex-direction: column;
          justify-content: center;
          bottom:120px;
          left:5%;
          h4{
              padding-right: 10px;
              font-size: 22px;
          }
        }
        &__flower{
            width:170px;
            height:140px;
            position: relative;
            left:540px;
            top:60px;
            opacity:0.6;
        }

    &__gallery{
        bottom:-2px;
        font-size: 14px!important;
        line-height: 14px;
    }
    &__google-location {
        .google-location {
          width: 40vw;
          height: 400px;
          position: relative;
          margin-left: -40vw;
          left: 50%;
        }
      }
      &__location-img{
          height:400px;
        width:100%;
          object-fit: cover;
      }
      &__location-info-desktop{
          position: absolute;
          bottom:83%;
          width: 70%;
      }
      &__location-title{

      }
      &__place{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        div{
            margin-right: 20px;
        }
      }
      &__church-title{

      }
      &__church-wrapper{
         width:100%;
      }

      &__writing{
          width:60%;
          padding-left: 15px;
      }

      &__contact-box {
        width: 25%;
      }

      &__story-title-box{
        display: flex;
        justify-content: left;
        margin-top: 40px;
        h2, h3{
            text-align: left;
            padding-left: 180px;
        }
        h3{
            padding-left: 230px;
        }

    }

    &__invitation-title{
       text-align: left;
       padding:60px 0 20px 15%;
       }
       &__last-arrow{
           height:45px;
           position: relative;
           right:340px;
           bottom:40px;
       }
       &__arrow-7{
          position: absolute;
          bottom: -330px;
          left:30%;
          right:initial;
       }

       &__story-box {
         width: 40%;
       }

       &__story-1 {
         padding-left: 0;
       }

       &__story-2 {
         display: flex;
         justify-content: center;
       }

       &__story-box-4, &__story-box-5, &__story-box-6 {
         width: 33%;
       }
       &__story-box-4 h4{
        text-align: center;
       }
       &__story-box-5 h4{
        text-align: center;
       }

    }


}
