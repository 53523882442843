@import '../../../resources/media-queries.scss';

.profile{
    
    .MuiTab-wrapper{
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 14px;
        text-transform: capitalize;
        
    }
    .MuiTabs-flexContainer {
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
    .MuiTabs-root {
        margin-left: -30px;
    }
    .MuiTabs-centered {
        justify-content: left;
    }
    &__change-password-form{
        margin:0 0 20px 0;
        width:100%;
        h4{
            font-family: "Montserrat", sans-serif!important;
            font-size: 14px;
        }
        
    }
    &__wrapper{
        margin-top: 40px;
    }
    &__subtitle{
        padding-top: 20px;
        padding-bottom: 5px;
    }
    &__submit-button{
        margin-left: 0;
        margin-top:20px;
    }
    &__button{
        margin-top: 10px;
    }
    @include desktop{
        &__profile-pic{
            display: flex;
            flex-direction: row;
            margin-top: 20px;
        }
        &__attachement-field{
            width:300px;
            margin-right: 20px;
        }
        &__change-password-form{
            width:30%;
        }
    }

}