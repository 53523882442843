@import '../../resources/media-queries.scss';

.drawer{
    &__name{
        font-weight: bold;
        font-size: 12px;
    }
    &__date{
        font-size: 10px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        right:0;
    }
    &__comment-input{
        position:relative;
        bottom:0;
        top:60%;
    }
    &__subtitle{
        font-size: 16px;
    }
    &__comment{
        font-size: 14px;
        color:#404040;
    }
    &__actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        left:80%;
        right:0;
        img{
            width:18px;
            height:20px;
            margin-left: 15px;
            cursor: pointer;
        }

        
    }
    &__line{
        width:90%;
        height:1px;
        background-color: #e6e6e6;
        margin:10px 0 20px;
       }
       &__actions-box{
           display: flex;
           flex-direction: row;
           justify-content: space-between;
       }
    @include desktop{

    }
}