@import '../../resources/media-queries.scss';
.website-form {
 
  &__section {
    padding: 10px 0;

  }
  &__contact-head{
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 0;

    h4{
      width: 20%;
      font-weight: bold;
      font-size: 14px;
      text-align: center;
    }
  }
  &__contact-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 12px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    margin:20px 0;
    h4{
      font-weight: 500!important;
    }

  }
  &__date-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__cell{
    width:20%;
    font-size: 14px;
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;
 
    text-align: center;

  }
  &__labels{
    display: flex;
    flex-direction: row;
    h4{
      width:50%;
    }
  }
  &__initials{
    border-radius: 50%;
    width:40px;
    height:40px;
    display: flex;
    flex-direction: row;
    position: absolute;
    left:0;
    justify-content: center;
    align-items: center;
}
  &__add{
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border:none;
    height:48px;
    width:170px;
    color: #e66300;
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    margin:10px 0;

    &:disabled {
      cursor: not-allowed;
    }
  }
  &__divider{
    height:1px;
    width:100%;
    margin:40px 0;
    background: rgba(0, 0, 0, 0.1);
  }
  &__textarea{
    border-radius: 12px;
    border: solid 1px rgba(230, 99, 0, 0.4);
    height:auto;
    width:100%;
    font-family: Roboto;
    font-size: 12px;
    padding:10px
  }
  &__textarea:focus{
    outline:none;
  }
  &__save{
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #e66300;
    color:#fff;
    width:155px;
    border:none;
    height:48px;
    margin-left: 40px;

  }
  &__subtitle{
    font-weight: 500!important;
    font-size: 18px;
  padding:15px 0 5px;
  }
  &__label{
    font-size: 14px;
    padding:15px 0 10px;
    font-weight: 500;
  }
  &__info{
    font-size: 12px;
    padding:10px 0 0;
    font-weight: bold;
  }
  &__actions{
    display: flex;
    flex-direction: row;
    img{
      width:18px;
      height:18px;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: #fff;
      padding:6px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  &__title{
    font-weight: 500;
    font-size: 18px;
  }
  &__tag{
    border-radius: 21.5px;
    border: solid 1px #e66300;
    padding:8px 5px;
    width:50%;

    h4{
      text-align: center;
      font-size: 12px;
    }
  }
  &__address{
    color: #006aff;
  }
  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;

  }
  &__text-and-pic{
    display: flex;
    flex-direction: row;
    margin:10px 0;
  }
  &__attachement{
    width:50%;
    margin-right:40px;
    
  }
  &__attachement-text{
    width:70%;
  ;
    
  }
  &__attachement-textarea{
    height:65px;
    font-size: 14px;
    line-height: 1.5;
  }
  .invitation-img-attachement{
    height:85px;
    margin-top: 18px;
  }
  &__sent-invitation-icon{
    width:18px;
    height:18px;
  }
  &__wedding-contacts{
    width:150%;
  }
  &__steps-wrapper{
    width:200%;
  }
  &__table-wrapper{
    justify-content: wrap;
    overflow-x: scroll;
    position: relative;
  }
  &__menus{
    width:100%;
  }
  @include desktop{
    padding: 20px;
    .invitation-img-attachement{
      margin-top: 0;
    }
    &__contact-head{
      display: flex;
      justify-content: flex-start;
      padding: 5px 20px 0;
      
  
      h4{
        width: 20%;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
      }
    }
    &__contact-row {
      display: flex;
      justify-content: flex-start;
      padding: 10px 20px;
      border-radius: 12px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      margin:20px 0;
      h4{
        font-weight: 500!important;
      }
  
    }
    &__wedding-contacts, &__steps-wrapper{
      width:100%;
    }
    &__table-wrapper{
      
    }
  }
}
