@import '../../resources/media-queries.scss';

.header{
    background-image: url('/assets/bottom-2.png');
    background-repeat: no-repeat;
    width:100%;
    background-size: cover;
    z-index:3;
    position: relative;

    &__container{
        padding:20px;
    }
    &__logo{
        width:200px;
    }
    @include tablet{
        &__container{
            padding:40px;
        }
    }
    @include desktop{
        transform: rotate(180deg);
        position: relative;
        &__container{
            padding:0 10% 40px;
            transform: rotate(180deg);
            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }
        &__hightlight{
            color:#e66300;
            border-bottom: 2px solid #e66300;
        }
        &__gradient{
            position: absolute;
            width:70%;
            right:0;
           z-index: 1;
            height:140px;
        }
        &__logo{
            width:330px;
            margin-top: 40px;
            height:120px;
        }
        &__menu{
            list-style-type: none;
            display: flex;
            z-index: 3;
            flex-direction: row;
            position: relative;
            justify-content: space-between;
            width:100%;
            margin:80px 0 0;
        }
        &__menu-items{
            width:60%;
        }
        &__item{
            font-family: PlayfairDisplay;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: #000;
            margin:0 40px;
            padding-bottom: 5px;
        }
        &__item:hover{
            color:#e66300;
            border-bottom: 2px solid #e66300;
        }
    }
}
