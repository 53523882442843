
@import '../../resources/media-queries.scss';
.attachment-form {
    &__upload-files {
        border: solid 1px rgba(230, 99, 0, 0.4);
        border-radius: 12px;
        height:auto;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        justify-content: center;
        align-content: center;
    }

&__upload-files:focus{
    outline:none;
}
    &__upload{
        font-size: 12px;
        text-align: center;
        color: #000;
        font-family: Roboto!important;
    }
    &__file-list{
        list-style-type: none;
        padding-left: 0;
        li{
            font-size: 14px;
            padding-right: 10px;
            font-family: Roboto;
        }
    }
    &__existing-files {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #0d0d0d;
      font-weight: 500;
    }
    &__upload-button{
        background: #fff;
        border:none;
        margin:20px auto;
        border-radius: 12px;
        color:#000;
        width:48px;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        height:48px;

        font-weight: bold;
        h5{
            font-size: 12px;
            font-family: Roboto;

        }
    }

    &__upload-icon{
        width:20px;
        height:20px;
    }
    &__delete-button{
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        width:40px;
        border:none;
        margin-left: 0;
        height:40px;
        z-index: 1;
    }
    &__delete-icon{
        width:16px;
        height:16px;
    }
    @include desktop{

        &__delete-button{

            cursor: pointer;
        }
    }
  }

  .drop-instruction{
      display: flex;
      flex-direction: row;
  }
