@import '../../resources/media-queries.scss';

.guest-modal{
    &__title{
        font-size: 22px;
        line-height: 1.6;
        padding-bottom: 15px;
        text-align: center;
        color: #130f36;
    }
    &__info-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin:20px 0;
        border-bottom: 1px solid #dfeaec;
        padding-bottom: 3px;
    }
    &__generic-info{
        font-size: 16px;
        padding-right: 10px;
        color: #130f36;
    }
    &__specific-info{
        font-size: 16px;
        line-height: 1.38;
        color: #1d2d2f;
    }
    &__action-buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        button{
            width:48%;
        }
    }
    &__delete-button{
        background-color: red;
        color:#ffff;
        font-size: 16px;
        border:none;
    }
    &__delete-button:hover{
        border:1px solid #fff;
    }
    &__close-icon{
        width:20px;
        position: relative;
        left:90%;
    }
    .price-modal-form{
        width:100%;
        input{
            width:100%;
            height:40px;

        }
        button{
            border:1px solid #32c3f3;;
            border-radius: 3px;
            height:38px;
            margin:20px auto;
            width:200px;
            font-weight: bold;
            background:#fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .admin__share-button {
          width: auto;
        }

        h4 {
          padding: 10px;
          font-size: 16px;
          line-height: 1.38;
          color: #1d2d2f;
        }
    }
    .price-close-icon{
        width:14px;
        top:-10px;
        right:-10px;
    }
    .price-modal{
        overflow-x: hidden;
        box-sizing: content-box;
    }
    &__table-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__guest-card{
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 12px;
        margin:8px auto;
        img{
           width:auto;
           height:20px;
           margin-right: 5px; 
        }
    }
    @include tablet{

    }
    @include desktop{
        padding:20px 50px;
        width:80%;
        &__info-box{
            padding-bottom: 8px;
        }
        &__submit-buttons{
            width:55%;
            margin-top: 30px;
        }
        &__close-icon{
            left:100%;
            cursor: pointer;
        }
    }
}
