@import '../../resources/media-queries.scss';

.gifts {
    &__header {
        padding: 20px;
    }

    &__reserve {
        background: #1992D4;
        border-radius: 4px;
        border: none;
        height: 40px;
        width: 146px;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        margin:10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__reserved{
        opacity:0.5;
    }
    &__reserved-banner{
        position: absolute;
        color:#fff;
        background-color: rgb(32, 154, 154);
        width:90%;
        display: flex;
        justify-content: center;
        margin-left: 10px;
        border-radius: 5px;
        top:50%;
        text-align: center;
        padding:10px;
    }

    @include desktop {
        &__header {
            padding: 40px 5%;
        }

        &__desktop-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            margin: 40px 10%;
            width: 80%;
        }

        &__gift-box {
            width: 50%;
            position: relative;
        }
        &__reserve{
            margin:0 0 10px;
            z-index: 10;
            position:relative;
            bottom:15px;
            display: block!important;
        }

        &__more-room {
            width: 60%;
        }
        &__shadow{
            position: absolute;
            top:0!important;
            left:0;
            opacity:0.8;
            width:100%!important;
            height:100%;
            z-index: 10;
        }
        &__reserved-banner{
            margin-left: 0;
            width:93%;
        }
    }
}
