@import '../../../resources/media-queries.scss';

.dashboard {
  &__statistics-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__statistics-chart {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(2, 16, 56, 0.05);
    padding-bottom: 30px;
    margin: 2%;
  }

  &__chart-title h2 {
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 20px;
    color: #021038;
    padding: 0 10%;
  }

  @include tablet {
    &__statistics-chart {
      width: 45%;
      height: 100%;
    }
  }

  @include desktop {
    &__statistics-chart {
      width: 25%;
      height: 100%;
    }
  }
}
