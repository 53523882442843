@import '../../resources/media-queries.scss';
.landing{
    .arrow {
        position: absolute;
        z-index: 12;
        cursor: pointer;
      }

      .nextArrow, .SecondNextArrow {
        top:82%;
        right:85%;
      }

      .prevArrow, .SecondPrevArrow{
       top:82%;
       left:85%;
      }
      .slider-arrow{
        width:50px;
        height:40px;
        object-fit: contain;
    }

   &__banner-slider{
       width:100%;
       height:230px;

       .slick-arrow{
        z-index:2;
     }
     .slick-prev {
        background: url('/assets/back-black.png') center center no-repeat!important;
        padding:22px 34px 22px 38px;
        left:0px;
        top:90%;
        &::before {
            display: none;
          }
      }
     .slick-next {
       background: url('/assets/next-black.png') center center no-repeat!important;
       padding:22px 34px 22px 38px;
       top:90%;
       right:0;
       &::before {
        display: none;
      }
     }
   }
   &__banner-slider-img{
       width:100%;
       height:230px;
       object-fit: cover;
   }
   &__social-media{
       display: flex;
       flex-direction: row;
       justify-content: center;
       margin:25px 0;
       img{
           width:20px;
           height:20px;
           margin:0 10px;

       }
   }
   &__section{
       padding:20px;
   }
   &__subtitle{
    font-family: PlayfairDisplay-Regular_;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
   }
   &__line{
       height:2px;
       background-color: #e66300;
       width:25px;
       margin:10px auto;
   }
   &__info{
    font-size: 14px;
    padding:5px 0;
    width:90%;
    font-weight: normal!important;
    margin:0 auto!important;
    text-align: center;
    text-indent: 10px;
   }
   &__action-button{
    color: #e66300;
    font-size: 20px;
    border:2px solid #e66300;
    margin:20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width:310px;
    height:64px;
    background:#fff;
   }
   &__colored-section{
    background-image: url('/assets/section-background.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgba(220, 200, 173, 0.2);
   }
   &__italic-info{
       font-family: PlayfairDisplay!important;
       font-size: 16px;
       line-height: 1.43;
       color: #2c2c2c;
   }
   &__bigger-font{
       font-size: 20px;
       padding-top: 20px;
   }
   &__benefits-container{
    margin:40px 0;
   }
   &__benefit{
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    margin:30px 0;
   }
   &__benefit-img{
       width: 160px;
       margin-right: 25px;
       object-fit: cover;
   }
   &__benefit-title{
       font-weight: bold;
       font-size: 16px;
       padding-top: 20px;

   }
   &__benefit-info{
       font-size: 14px;
       padding-top: 20px;
   }
   &__benefit-line{
       margin-left: 0;
   }
   &__benefit-number{
       position: absolute;
       font-size: 38px;
       left:40%;
       top:-23px;
   }
   &__list{
       margin:20px 0;
       li{
        line-height: 1.71;
        letter-spacing: normal;
        color: #2c2c2c;
        font-size: 14px;
        font-weight: bold;
        margin:5px 0;
       }
   }
   &__review-section{
       background-color: #fff;
   }
   &__review-slider{
       width:100%;
       margin-bottom: 40px;
       position: relative;
       .slick-dots li button{
           color:#e66300;
           background:#e66300!important;
            padding:1px 20px;
            margin-top: 10px;
            height:2px;
            position: absolute;
            top:0;

           &::before {
            display: none;
          }
          .slick-active{
              color:green;
          }
       }
       .slick-dots li{
           margin:0 20px;
       }
       .slick-dots li active{
           background:green;
       }
       .slick-dots li button active{
           background:Red;
           color:green;
           border:1px solid green;
       }

   }
   &__quotes-up{
    margin-bottom:10px;
   }
   &__quotes-down{
    position: relative;
    left:85%;
   }
   &__review-top{
       display: flex;
       flex-direction: row;
       margin:20px 0;
       h3{
           font-size: 36px;
           font-weight: bold;
           padding-right: 15px;
       }
       h4{
           font-weight: bolder;
           font-size: 18px;
           position: relative;
           top:15px;
       }
   }
   &__review-slider-img{
       width:100px;
       height:150px;
       display: flex;
       align-self: center;
       margin-right: 10px;
       object-fit: cover;
   }
   &__review-box{
       display: flex;
       flex-direction: row;
   }
   &__review-text{
       p{
           padding:3px 0;
           text-indent: 20px;
           color: #2c2c2c;
           font-weight: bold;
           font-style:italic;
       }
   }
   &__final-section{
    background-image: url('/assets/final-section.jpeg');
    background-repeat: no-repeat;
    padding-top: 40px;
    background-size: cover;
   }
   &__final-info{
    font-size: 14px;
    padding:20px 0;
    text-indent: 10px;
    text-align: center;
   }
   &__final-button{
    width: 233px;
    height: 48px;
    color:#fff;
    background-color:#e66300;
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin:20px auto;
   }
   &__final-subtitle{
       text-align: center;
   }
   &__outline{
    border:2px solid #e66300;
    margin-top: 30px;

   }
   &__offer{
       margin:10px;
       border:2px solid #e66300;
   }
   &__offer-title{
       color:#e66300;
       font-size: 26px;
       text-align: center;
       padding:20px;
   }
   &__price{
       text-align: center;
       font-size: 20px;
       font-weight: bold;
   }
   &__offer-list{
       margin:20px 0;
       li{
           color:black;
           font-weight: bold;
           margin:10px 0;
       }
   }
   &__italic-info{
       font-weight: lighter;
   }
    @include tablet{
        &__banner-slider{
            height:330px;
        }
        &__banner-slider-img{
            height:330px;
        }
        &__section{
            padding:40px;
        }
        &__benefit-number{
            left: 20%;
            top: -22px;
        }
        .nextArrow {
            top:88%;
            right:90%;
          }

          .prevArrow {
           top:88%;
           left:90%;
          }
    }
    @include desktop{
      .slider-arrow {
        width: 70px;
      }
        &__banner-slider{
            width:100%;
            height:230px;

            .slick-arrow{
          }
          .slick-prev {
             top:97%;
           }
          .slick-next {
            top:97%;
          }
        }
        &__banner-slider{
            height:700px;
            width:430px;
            margin-left:20px;
        }
        &__banner-slider-img{
            height:700px;
            width:430px
        }
        &__slider-box{
            display: flex;
            flex-direction: row;
        }
        &__title{
            font-size: 52px;
            font-weight: bold;
            transform: rotate(180deg);
            writing-mode: vertical-lr;
            text-align: center;

        }
        &__first-part{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding:40px 10%;
            background-image: url('/assets/first-section-background.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
        &__social-media{
            flex-direction: column;
            img{
                margin:20px 0;
            }
        }
        &__top-section{
            display: flex;
            flex-direction: row-reverse;
            margin-left: 40px;
        }
        &__top-info{
            margin-top: 50px;
        }
        &__main-title{
            font-size: 54px;
            text-align: left;
        }
        &__main-line{
            margin-left: 0;
            margin:40px 0;
        }
        &__info{
            font-size: 26px;
            padding:10px 0;
        }
        &__action-button{
            margin-top: 40px;
        }
        &__subtitle{
            font-size: 48px;
        }
        &__story-section{
            background-image: url('/assets/section-background.png')!important;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: rgba(220, 200, 173, 0.2)!important;
            padding:60px;
        }
        &__italic-info{
            width:65%;
            font-size: 20px!important;
            font-weight: lighter;
        }
        &__benefits-container{
            display: flex;
            flex-direction: row;
            padding:40px 10%;
            justify-content: center;
            flex-wrap: wrap;
        }
        &__benefit{
            width:45%;
            margin:40px 0;
        }
        &__benefit-img{
            width:200px;
            height:240px;
        }
        &__benefit-title{
            font-size: 18px;
        }
        &__benefit-number{
            font-size: 44px;
            left:27%;
            top:-40px;
        }
        &__third-section{
            display: flex;
            padding:40px 10%;
            flex-direction: row;
            background-image: url('/assets/section-background.png');
            background-repeat: no-repeat;
            background-size: contain;
            background-color: rgba(220, 200, 173, 0.2);
        }
        &__site-slider, &__site-one-slider, &__site-slider-img{
            width:630px;
            height:435px;
        }
        &__colored-section{
            background-image: none;
            background-color: transparent;
        }
        &__left-title{
            text-align: left;
        }
        &__review-desktop{
            padding:40px 10%;
        }
        .landing__review-top h4 {
            top: 18px;
        }
        &__review-slider-img{
            width:200px;
            height:300px;
        }
        &__review-top{
            margin-left: 10%;
        }
        &__review-text{
            margin-top: 100px;
            width:60%;
            margin-left: 70px;
        }
        &__outline{
            margin:40px 35%;
        }
        &__offer-list li{
            margin:24px 0;
            color: #2c2c2c;
        }
        &__final-section{
            padding:40px 25%;
        }
        &__final-info{
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .nextArrow {
            top:94%;
            right:87%;
          }

          .prevArrow {
           top:94%;
           left:87%;
          }
        .SecondNextArrow{
            top:90%;
            right:91%;
        }
        .SecondPrevArrow{
            top:90%;
            left:91%;
        }
    }
}
