@import '../../../resources/media-queries.scss';

.edit{
    &__form{
        width: 90%;
    }
    @include desktop{
        &__form{
            width:70%;
        }
    }
}