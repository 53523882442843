@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(fadeInImg) {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

.img-loading {
  opacity: 0;
  width: 100%;
  height: auto;
}

.img-loaded {
  animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
  position: relative;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-delay: 0.1s;
}
