.tables-configurator {
  &__top-buttons {
    display: flex;
    align-items: center;
  }

  &__guests-list-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;

    h5 {
      margin-right: 50px;
      padding: 5px;
    }
  }

  &__guests-list-buttons {
    display: flex;
    align-items: flex-end;
  }

  &__wrapper {
    margin: 5px 15px;
  }

  &__drag-wrapper {
    position: relative;
    width: 100%;
    height: 700px;
    box-shadow: 0 0 10px 10px #ccc;
    border-radius: 10px;
  }

  &__table {
    width: 50%;
  }

  &__table-wrapper {
    width: 80px;
    height: 80px;
    position: relative;
    touch-action: none;
    user-select: none;
  }

  &__table-wrapper-square {
    width: 100px;
    height: 100px;
  }

  &__table-wrapper-round--selected {
    border: 1px solid #e66300;
    border-radius: 50%;
  }

  &__table-wrapper-square--selected {
    border: 1px solid #e66300;
  }

  &__table-round {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 50px #ccc;
    width: 80px;
    height: 80px;
    position: relative;
  }

  &__table-square {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 50px #ccc;
    width: 100px;
    height: 100px;
    position: relative;
  }

  &__handle {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    cursor: move;
  }

  &__table-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    h6 {
      margin: 0;
    }
  }

  &__table-number {
    color: #e66300;
  }

  &__chair {
    position: absolute;
    z-index: 3;
    cursor: pointer;

    &.drop-active {
      border: 1px solid lightgreen;
    }

    &.drop-active--no-drop {
      border: 1px solid red;
    }

    &.drop-target {
      border: 2px solid green;
    }
  }

  &__chair-initials {
    position: absolute;
    top: 30%;
    left: 20%;
    color: white;
  }

  &__chair-guest-details {
    position: absolute;
    top: -5px;
    left: -5px;
    align-items: center;
    padding: 0!important;
    padding-left: 60px!important;
    margin: 2px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    background-color: white;
    z-index: -1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    h4 {
      min-width: 150px;
      height: 33px;
      line-height: 33px;
    }

    &.hidden {
      display: none;
    }
  }

  &__chair-guest-details--delete {
    position: absolute;
    left: 35px;
    align-items: center;
    height: 24px;
    width: 24px;
    border: 1px solid #e66300;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }

  &__config-section {
    display: flex;
    justify-content: space-between;
  }

  &__guests-list {
    position: absolute;
    background-color: white;
    border: 1px solid #e66300;
    top: 105px;
    right: 30px;
    z-index: 2;
  }

  &__guest {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    touch-action: none;
    transform: translate(0px, 0px);

    img {
      width: 30px;
      height: 30px;
    }

    h4 {
      width: 150px;
    }
  }

  &__button {
    margin: 0 10px;
  }

  &__button--disabled {
    cursor: not-allowed;
  }

  &__export-wrapper {
    margin: 20px 40px;
    display: flex;
    justify-content: center;

    button {
      margin: 0 5px;
    }
  }

  &__search-form {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }

  &__search-input {
    width: 50%;

    input {
      text-indent: 10px;
    }
  }

  &__search-select {
    width: 40%;
  }

  &__search-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__close-search {
    position: relative;
    top: 0;
  }
}
