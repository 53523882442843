@import '../../../resources/media-queries.scss';

.website-builder {
  &__preview {
    display: flex;
    align-items: baseline;
  }
  &__wrapper{
    padding:20px;
  }
}
