@import '../../resources/media-queries.scss';

.contact-modal{
 
    &__title{
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    &__subtitle{
        text-align: center;
        font-size: 16px;
        width:60%;
        margin:0 auto!important;
        font-weight: 600;
    }
    &__container{
        margin-top: 20px!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__close-icon{
        width:14px!important;
        cursor: pointer;
        position: absolute;
        right:20px;
    }
    &__yes{
        font-family: Montserrat;
        font-size: 14px;
        color:#000;
        width:142px;
        height:46px;
        border:none;
        background-color: transparent;
        font-weight: bold;
    }
    &__no{
        border-radius: 12px;
        box-shadow: 0 4px 16px 0 rgba(109, 109, 109, 0.2);
        background-image: linear-gradient(104deg, #00e5ff -66%, #00c6ff 99%);
        width:142px;
        font-size: 14px;
        color:#fff;
        border:none;
        height:46px;
    }
    &__existing-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 30px;
    }
    &__contact-box{
        display: flex;
        flex-direction: row;
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        width:300px;
        align-items: center;
        height:40px;
        justify-content: center;
        margin:15px 0;
        padding:20px 0;
        img{
            width:20px;
            height:20px;
            margin-right: 5px;
        }
    }
    @include tablet{

    }
    @include desktop{
        z-index:100;
        position: relative;
    }
}