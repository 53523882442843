@import '../../resources/media-queries.scss';

.gallery{
    &__wrapper{
        min-height: 100vh;
        height:500px;
        background-repeat: repeat;
        width:100%;
        background-size: cover;
        background-position: center;
        margin-top: 20px;
        
    }

    &__title{
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        font-weight: lighter;
        padding-top: 40%;
    }
    &__form{
        button{
            background:transparent;
            color:#fff;
            border:1px solid #fff;
            width:120px;
            height:40px;
            display: flex;
            justify-content: center;
            margin:40px auto 0;
            align-items: center;
        }
    }
    &__label{
        font-size: 12px;
        line-height: 12px;
        color: #FFFFFF;
        padding:80px 0 20px;
        text-align: center;
    }
    &__input{
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        height:44px;
        display: flex;
        justify-content: center;
        margin:0 auto;
        width:80%;
    }
    .MuiTab-wrapper{
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #000000;
        width:160px;
        font-family: 'Playfair Display', serif;
        font-weight: bold;
    }
    .Mui-selected{
       border-bottom: 1px solid #D1E9F7;
        
    }
    .MuiTabs-root{
       
    }
    &__gallery-tabs{
       
    }

    @include tablet{
        &__title{
            font-size: 30px;
            padding-top: 30%;
        }
        &__input{
            width:60%;
        }
    }
    @include desktop{
        &__title{
            font-size: 32px;
            line-height: 40px;
            padding-top: 12%;
        }
        &__input{
            width:20%;
        }
        .MuiTab-wrapper{
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.1em;
            color: #000000;
            width:100%;
            font-family: 'Playfair Display', serif;
            font-weight: bold;
        }
    }
}