@import '../../../resources/media-queries.scss';

.supreme{
    background-image: url('/assets/bottom-2.png');
    background-size: cover;
    min-height: 100vh;
    display: flex;
    margin:0!important;
    padding:0;
    flex-direction: row;
    justify-content: space-between;
    &__container{
        width:80%;
        padding:40px 10% 40px 5%;
    }
    &__title{
        text-align: center;
        padding-bottom: 60px;
        font-size: 28px;
        display: flex;
    }
    &__add-client{
        border-radius: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: #e66300;
        border:none;
        color:#fff;
        height:48px;
        width:150px;
    }
    &__logo-icon{
        width:40px;

        object-fit: contain;
    }
    &__color-box{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        h4{
            margin-left: 10px!important;
        }

    }
    &__circle{
        width:15px;
        height:15px;
        border-radius: 50%;

    }
    .yellow{
        background-color: yellow;
    }
    .green{
        background-color: green;
    }
    .red{
        background-color: red;
    }
    &__user, &__bills, &__client-actions {
        border-bottom: 1px solid #e66300;
    }
    &__subtitle{
        padding-top: 30px;
    }
    &__invoice{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;
    }
    &__attachement-field{
        width:300px!important;
    }

    &__client-wrapper {
      width: 100%!important;
    }

    @include desktop{

    }
}
