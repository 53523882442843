@import '../../resources/media-queries.scss';

.event{
    background: #1992D4;
    min-height: 100vh;
    &__container{
        background:white;
        margin:40px 20px 20px;
        padding:20px;
        border-radius: 10px;
        position: absolute;
    }
    &__form{
        width:90%;
    }
    &__submit-button{
        background: #1992D4;
        border-radius: 4px;
        border: none;
        height: 40px;
        width: 146px;
        color: #fff;
        font-size: 12px;
        margin-top: 10px;
    }
    &__select{
        margin:40px 0 20px;
        background: #FFFFFF;
        border: 1px solid #A3D3EE;
        border-radius: 4px;
        height:40px;
        width:100%;
        font-size: 14px;
    }
    &__select:focus{
        outline: none;
    }
    @include tablet{
        margin:none;
        &__form{
            width:70%;
        }
        &__container{
            margin:40px;
            width:70%;
        }
    }
    @include desktop{
        &__form{
            margin-top: 20px;
        }
        &__container{
            padding: 40px;
            margin:40px 25%;
            width:40%;
        }
    }
}