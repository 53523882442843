
@import '../../resources/media-queries.scss';

.article{
    &__container{

    }
    &__image-div{
        position: relative;
    }
    &__wrapper{
        padding:20px;
        background-image:url('/assets/blog-1.png');
        background-repeat: no-repeat;
    }
    &__img-shadow{
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: inset -6px -33px 35px 9px rgba(255,255,255,0.98);
        box-shadow: inset -6px -33px 35px 9px rgba(255,255,255,0.98);
        z-index: 54;
        top: 0;
        left: 0;
    }
    &__title{
        font-weight: bold;
        font-size: 24px;
        line-height: 23px;
        text-align: center;
        color: #000000;
        padding-bottom: 20px;
    }
    &__first-paragraph{
        font-style:italic;
        text-align: center;
    }
    &__main-img, &__image{
        height:320px;
        object-fit: cover;
    }
    &__time-box{
        margin-top: 0;
    }
    &__main-img {
      width: 100%;
        margin-bottom: 10px;
    }

    &__image {
      max-width: 100%;
      display: block;
      margin: 10px auto;
    }

    &__paragraf{
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        padding:10px 0;
        font-family: DidactGothic!important;
        text-align: left;
        text-indent: 10px;
    }
    &__subTitle{
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        font-style:italic;
        line-height: 21px;
        color: #000000;
        padding:10px 0;
    }

    &__social{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin:10px auto 15px;
        width:30%;
        img{
            width:22px;
            cursor: pointer;
            height:22px;

        }
    }
    &__social-bottom{
        padding-top: 20px;
    }
    &__subfooter{
        padding-bottom: 20px;
    }
    &__actions-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__action{
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        img{
            width:14px;
            height:14px;
            margin:0 5px;
            position: relative;
            top:3px;
        }
        h3{
            font-size: 16px;
            line-height: 18px;
            color: #646666;
            font-weight: lighter;
        }
        h3:hover{
            color:black;
        }
    }
    @include tablet{
        &__container{

        }
    }
    @include desktop{
        &__container{
           position: relative;
           background-image: url('/assets/blog-1.png');
        }

        &__image-div {
          height: 470px;
        }

        &__img-shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.86) 60%, #fff 81%, #fff 81%);
          z-index: 2;
          top: -200px;
          right: 0;
          height: 675px;
          box-shadow: none;
        }
        &__title{
            font-size: 48px;
            line-height: 56px;
            padding-top: 20px;
            width:60%;
            margin:0 auto!important;
        }
        &__first-paragraph{
            font-size: 24px;
        }
        &__paragraf{
            font-size: 18px;
            line-height: 1.71;
            text-indent: 20px;

            margin:0 auto!important;
        }
        &__social{
            width:10%;
        }
        &__main-img{
           position: absolute;
           top:-220px;
           height:670px;
           z-index: 1;
        }
        &__wrapper{
            z-index: 4;
            position: relative;
            background-color: #fff;
            margin:0 15%;
            background-image: none;
            padding:40px 7%;
            margin-top: -100px;
        }
        &__time-box{
            justify-content: center;
            margin-bottom: 20px;
        }
        &__subTitle{
            font-size: 24px;
            line-height: 34px;
        }
        &__actions-box{
            margin:40px 15%;
        }
        &__action{
            img{
                top:0;
            }
            h3{
                font-size: 18px;
            }
        }
        &__all-articles{
            background-image: none;
            background-color: #fff;
            margin:40px auto;
            padding:40px 80px;
        }
        &__articles-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }
        &__blog-subtitle{
          font-size: 24px;
        }
        &__single-article{
            width:31%;
        }
        &__article-read-box{
            display: flex;
        }
        &__blog-img{
            width:150px;
            height:150px;
            margin-top: 40px;
        }
    }
}
